import axios from "axios"

import {BASE_URL} from '../utils/config'
const URL = BASE_URL;//"http://localhost:3001";

export const generateOtp = async (verifier, verificationType) => {
    try {
        if (!verifier || !verificationType) {
            throw new Error("Invalid data")
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const {data} = await axios.post(
            `${URL}/api/otp/create`,
            {
                verifier,
                verificationType,
            },
            config
        );

        return data;
    } catch (err) {
        throw new Error(err);
    }
}

export const matchOtp = async (userId, otp) => {
    try {
        console.log(userId, otp);
        if (!userId || !otp) {
            throw new Error("Invalid data");
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const {data} = await axios.post(
            `${URL}/api/otp/match`,
            {
                userId,
                otp,
            },
            config
        );

        return data;
    } catch (err) {
        console.error(err);
    }
}
