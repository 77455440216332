import React, {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import {useGoogleLogin} from "@react-oauth/google";
import {AccountContext} from "../context/AccountProvider";
import {addAccount, getAccount} from "../service/accountApi";
import Cookies from "universal-cookie";
import EmailPhoneInput from "../components/EmailPhoneInput";
import {Transition, TransitionChild} from "@headlessui/react";
import {t} from "i18next";

const Login = () => {
    const cookies = new Cookies();

    const [user, setUser] = useState(null);
    const {setAccount, setCookie} = useContext(AccountContext);

    const [isOpen, setIsOpen] = useState(false);
    const [emailWithNoAccount, setEmailWithNoAccount] = useState(null);

    const [yesButtonDisabled, setYesButtonDisabled] = useState(false);

    useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: "application/json",
                        },
                    }
                )
                .then(async (res) => {
                    getAccount({email: res.data.email})
                        .then((accountFetched) => {
                            console.log("Account Fetched from Server: ", accountFetched);
                            if (accountFetched) {
                                console.log("Account Fetched Email: ", accountFetched.email);
                                cookies.set("email", accountFetched.email);
                                setAccount((prevState) => ({
                                    ...prevState,
                                    ...accountFetched,
                                }));
                                setCookie(cookies.get("email"));
                                // navigate('/home', {replace: true});
                            } else {
                                setEmailWithNoAccount(res.data.email);
                                setIsOpen(true);
                            }
                        })
                        .catch((err) => console.log("Error Fetching Account Details: ", err));
                })
                .catch((err) => console.log("Error fetching Google Account Details: ", err));
        }
    }, [user]);

    const addNewAccount = () => {
        setYesButtonDisabled(true);
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: "application/json",
                        },
                    }
                )
                .then(async (res) => {
                    console.log("Google Account Login Response: ", res);
                    console.log("Google Account Data: ", res.data);

                    const data = {
                        googleId: res.data.id,
                        email: res.data.email,
                        firstName: res.data.given_name,
                        imageUrl: res.data.picture,
                        verified_email: res.data.verified_email,
                        locale: res.data.locale
                    }

                    console.log("New Account to be Added: ", data);
                    addAccount(data)
                        .then((accountAdded) => {
                            console.log("Account Added: ", accountAdded);
                            if (accountAdded) {
                                console.log("Account Added Email: ", accountAdded.email);
                                cookies.set('email', accountAdded.email);
                                setAccount(prevState => ({
                                    ...prevState,
                                    ...accountAdded
                                }));
                                setCookie(cookies.get('email'));
                                setYesButtonDisabled(false);
                                // navigate('/home', {replace: true});
                            }
                        })
                        .catch((err) => console.log("Error Adding Account: ", err));
                });
        } else {
            const data = {
                email: emailWithNoAccount,
                verified_email: true,
                locale: 'en-GB'
            }
            console.log("New Account to be Added: ", data);
            addAccount(data)
                .then((accountAdded) => {
                    console.log("Account Added: ", accountAdded);
                    if (accountAdded) {
                        console.log("Account Added Email: ", accountAdded.email);
                        cookies.set('email', accountAdded.email);
                        setAccount(prevState => ({
                            ...prevState,
                            ...accountAdded
                        }));
                        setCookie(cookies.get('email'));
                        setYesButtonDisabled(false);
                        // navigate('/home', {replace: true});
                    }
                })
                .catch((err) => console.log("Error Adding Account: ", err));
        }
    }

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            console.log('Google Login Response: ', codeResponse);
            setUser(codeResponse);
        },
        onError: (error) => {
            console.log("Login Failed:", error)
        },
    })

    return (
        <>
            <div className="h-[100vh] w-full flex flex-row items-center justify-center">
                <div className={"flex-1 h-full"}>
                    <img
                        src="landing/header.png"
                        alt=""
                        className="h-full object-cover shadow-lg"
                    />
                </div>
                <div className="flex flex-1 flex-col items-center justify-center">
                    <h1 className="text-center leading-[1.25] mb-[2vh] text-5xl text-transparent font-SOHNE_EXTRA_BOLD bg-clip-text bg-yellow-400 bg-gradient-to-br from-yellow-400 to-red-500">
                        {t("LOGIN_PAGE.GREETING")}
                    </h1>

                    <div className="w-[25vw] flex flex-col gap-6 items-center">

                        {/* Email | Phone Number */}
                        <div className="w-full">
                            <EmailPhoneInput
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                setEmailWithNoAccount={setEmailWithNoAccount}
                            />
                        </div>

                        {/* Divider */}
                        <p className={"font-SOHNE_MEDIUM"}>
                            {t("LOGIN_PAGE.OR")}
                        </p>

                        {/* Login With Google */}
                        <div className={"inline-flex items-center justify-between w-full"}>
                            <button
                                onClick={login}
                                type="button"
                                role="link"
                                tabIndex={0}
                                aria-label="Sign in with Google"
                                className="flex flex-1 items-center justify-center bg-white text-[#000022] border-2 border-gray-300 rounded-full px-[1vw] py-[1.5vh] gap-[0.5vw] hover:bg-[#000022] hover:text-white hover:ring-indigo-600 hover:ring-offset hover:ring-4 transition-all duration-300 ease-in-out"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    className="w-[1.5vw] h-[1.5vw]"
                                >
                                    <title>Continue with Google</title>
                                    <desc>Google G Logo</desc>
                                    <path
                                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                        fill={"#1976D2"}
                                    />
                                    <path
                                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                        fill={"#4CAF50"}
                                    />
                                    <path
                                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                        fill={"#FFC107"}
                                    />
                                    <path
                                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                        fill={"#FF3D00"}
                                    />
                                </svg>
                                <span className="font-SOHNE_MEDIUM leading-tight">
                                    {t("LOGIN_PAGE.GOOGLE")}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/*<Dialog*/}
            {/*    open={isOpen}*/}
            {/*    as="div"*/}
            {/*    className="relative z-10 focus:outline-none"*/}
            {/*    onClose={() => setIsOpen(false)}*/}
            {/*>*/}
            <Transition show={isOpen}>
                <div
                    className="min-h-full w-screen flex items-center justify-center fixed inset-0 z-10
                    overflow-y-scroll no-scrollbar bg-black/75 backdrop-blur-lg
                    data-[closed]:opacity-0
                    data-[enter]:duration-75 data-[enter]:data-[closed]:ease-out
                    data-[leave]:duration-300 data-[leave]:data-[closed]:ease-out"
                >
                    <TransitionChild>
                        <div
                            className="transform z-50 h-fit w-full max-w-lg rounded-xl
                            ring-4 ring-[#050A0F] p-[1vw] bg-black/5
                            data-[enter]:duration-100 data-[enter]:data-[closed]:scale-75
                            data-[leave]:duration-300 data-[leave]:data-[closed]:scale-95"
                        >
                            <h2 className="font-SOHNE_MEDIUM text-xl text-white">
                                {t("LOGIN_PAGE.NO_ACCOUNT_FOUND.TITLE")}
                            </h2>
                            <p className="my-[2vh] text-md text-white/70 font-SOHNE_REGULAR leading-relaxed">
                            <span className={"font-SOHNE_MONO_MEDIUM underline"}>
                                {emailWithNoAccount}
                            </span>
                                {t("LOGIN_PAGE.NO_ACCOUNT_FOUND.DESCRIPTION")}
                            </p>
                            <div className="mt-[1vh] flex flex-row items-center gap-x-[1vw]">
                                <button
                                    disabled={yesButtonDisabled}
                                    onClick={addNewAccount}
                                    className="font-SOHNE_MEDIUM inline-flex items-center arrow-button bg-[#D26A53] leading-tight hover:ring-white hover:ring-2 disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full pl-[1.25vw] pr-[0.75vw] py-[1vh] text-white transition-all duration-300 ease-in-out"
                                >
                                    {t("LOGIN_PAGE.NO_ACCOUNT_FOUND.OPTION_1")}
                                    <svg
                                        viewBox="0 0 6 6"
                                        fill="none"
                                        id={"arrow-icon"}
                                        className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3 1L5 3L3 5"
                                            id={"arrow-icon-tip"}
                                            stroke="white"
                                            strokeLinecap="square"
                                        />
                                        <path
                                            d="M1 3L4 3"
                                            id={"arrow-icon-line"}
                                            stroke="white"
                                            strokeLinecap="square"
                                        />
                                    </svg>
                                </button>
                                <button
                                    disabled={yesButtonDisabled}
                                    className="inline-flex items-center gap-2 rounded-full ring-inset ring-2 ring-white py-[1vh] px-[2vw] text-SM font-SOHNE_MEDIUM text-white focus:outline-none hover:bg-gray-600 focus:outline-1 focus:outline-white transition-all duration-300 ease-in-out"
                                    onClick={() => setIsOpen(false)}
                                >
                                    {t("LOGIN_PAGE.NO_ACCOUNT_FOUND.OPTION_2")}
                                </button>
                            </div>
                        </div>
                        {/*</Dialog>*/}
                    </TransitionChild>
                </div>
            </Transition>
        </>
    )
}

export default Login;
