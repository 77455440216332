import React, {useContext, useEffect} from "react";
import {AccountContext} from "./context/AccountProvider";
import MainPage from "./pages/MainPage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import LandingPageScreen from "./pages/LandingPageScreen";
import Login from "./pages/Login";
import {VanshVeli} from "./pages/VanshVeli";
import {Information} from "./pages/Information";
import {Dashboard} from "./pages/Dashboard";

export default function App() {
    const {cookie, account} = useContext(AccountContext);

    useEffect(() => {
        console.log("(App) Cookie in useEffect: ", cookie);
    }, []);

    if (!cookie) {
        console.log("Cookie is null!");
        return (
            <BrowserRouter basename={"/"}>
                <Routes>
                    <Route
                        path="*"
                        element={<Navigate to="/" replace/>}
                    />
                    <Route
                        path="/"
                        element={<LandingPageScreen/>}
                    />
                    <Route
                        path="/login"
                        element={<Login/>}
                    />
                </Routes>
            </BrowserRouter>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="*"
                    element={<Navigate to="/home" replace/>}
                />
                <Route
                    path="/home"
                    element={<MainPage/>}
                />
                <Route
                    path="/vansh-veli"
                    element={<VanshVeli/>}
                />
                <Route
                    path="/info"
                    element={<Information/>}
                />
                <Route
                    path="/dashboard"
                    element={<Dashboard/>}
                />
                {/*<Route path="/builder" element={cookie ? <MainPage/> : <LandingPageScreen/>}/>*/}
                {/*<Route path="/joglekarkul" element={<LandingPageScreen/>}/>*/}
            </Routes>
        </BrowserRouter>
    );
}
