import React, {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {footerNav} from "../utils/landingPageContent";
import FlyoutMenu from "../components/FlyoutMenu";
import Cookies from "universal-cookie";
import {t} from "i18next";

const LandingPageScreen = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [locale, setLocale] = useState(cookies.get("locale") || "en");

    const handleLanguageDropdown = (e) => {
        console.log("e.target.value: ", e.target.value)
        cookies.set("locale", e.target.value, {path: "/"})
        window.location.reload();
    }

    return (
        <div className="bg-white">
            <Navigate to="/" replace={true}/>

            {/* Header */}
            <nav
                className="rounded-full fixed inset-x-5 top-5 z-50 flex items-center justify-between bg-white shadow-md px-[1vw]"
                aria-label="Global"
            >
                <div className="flex grow-0 py-[1vh]">
                    <a href="/" className="flex items-center">
                        <img
                            className="h-[3vw] w-auto px-[1vw]"
                            src="landing/ganesh_logo_image.png"
                            alt="Joglekarkul Logo"
                        />
                        <h1 key="logo-name" className={"font-SOHNE_BOLD"}>
                            {t("HEADER.JOGLEKARKUL")}
                        </h1>
                    </a>
                </div>

                <div className="flex gap-x-12">
                    <a
                        href="/"
                        key="Home"
                        className="text-md flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900 py-[1vh]"
                    >
                        {t("HEADER.HOME")}
                    </a>
                    <a
                        href="/vansh-veli"
                        key="VanshVeli"
                        className="text-md flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900 py-[1vh]"
                    >
                        {t("HEADER.VANSH_VELI")}
                    </a>
                    <a
                        href="/info"
                        key="Information"
                        className="text-md flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900 py-[1vh]"
                    >
                        {t("HEADER.INFORMATION")}
                    </a>
                    <a
                        href="/dashboard"
                        key="Dashboard"
                        className="text-md flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900 py-[1vh]"
                    >
                        {t("HEADER.DASHBOARD")}
                    </a>
                    <FlyoutMenu/>
                </div>

                <div className="flex items-center gap-x-[2vw] py-[1vh]">
                    {/* Language Selection */}
                    <div className={"flex flex-row"}>
                        <select
                            value={locale}
                            onChange={(e) => handleLanguageDropdown(e)}
                            id="small"
                            className="text-sm text-gray-900 rounded-lg ring-0"
                        >
                            <option value="en">English</option>
                            <option value="mr">मराठी</option>
                        </select>
                    </div>

                    {/* Login Button */}
                    <button
                        onClick={() => navigate("/login")}
                        className="arrow-button inline-flex items-center bg-[#D26A53] hover:ring-orange-300 hover:ring-offset-1 hover:ring-4 rounded-full pl-[1vw] pr-[0.6vw] py-[1vh] shadow-xl transition-all duration-300 ease-in-out hover:bg-[#D26A53]"
                    >
                        <p className={"font-SOHNE_MEDIUM leading-tight text-base text-white"}>
                            {t("HEADER.LOGIN")}
                        </p>
                        <svg
                            viewBox="0 0 6 6"
                            fill="none"
                            id={"arrow-icon"}
                            className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3 1L5 3L3 5"
                                id={"arrow-icon-tip"}
                                stroke="white"
                                strokeLinecap="square"
                            />
                            <path
                                d="M1 3L4 3"
                                id={"arrow-icon-line"}
                                stroke="white"
                                strokeLinecap="square"
                            />
                        </svg>
                    </button>
                </div>
            </nav>

            <main className="isolate">
                {/* Hero section */}
                <div id="top" className="relative h-[80vh]">
                    <img
                        src="landing/header.png"
                        alt=""
                        className="w-full h-full object-cover shadow-lg"
                    />
                </div>

                {/* Info section */}
                <div className="mx-auto max-w-7xl px-6 mt-[8vh] flex justify-center flex-col">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-3xl tracking-tight text-[#D26A53] border-b-2 border-[#D26A53] py-1 font-SOHNE_MEDIUM">
                            {t("HOME_PAGE.GREETING")}
                        </h2>
                    </div>

                    <div className="mx-auto mt-[6vh] max-w-6xl">
                        <dl className=" grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            <div key="कुलप्रतिष्ठान" className="relative ">
                                <dt className="font-SOHNE_MEDIUM_ITALIC leading-2 text-[#D26A53] border-b-2 border-[#D26A53]">
                                    {t("HOME_PAGE.BODY.SECTION_1.TITLE")}
                                </dt>
                                <dd className="font-SOHNE_REGULAR mt-[1vh] text-base text-gray-900 text-justify">
                                    {t("HOME_PAGE.BODY.SECTION_1.CONTENT")}
                                </dd>
                            </div>

                            <div key="इतिहास" className="relative ">
                                <dt className="font-SOHNE_MEDIUM_ITALIC text-[#D26A53] border-b-2 border-[#D26A53]">
                                    {t("HOME_PAGE.BODY.SECTION_2.TITLE")}
                                </dt>
                                <dd className="font-SOHNE_REGULAR mt-[1vh] text-gray-900 text-justify">
                                    {t("HOME_PAGE.BODY.SECTION_2.CONTENT")}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </main>

            {/* Footer */}
            <footer className="bg-[#D26A53] mt-20">
                <div
                    className="flex flex-row justify-between w-full overflow-hidden py-5 px-16 text-white"
                >
                    <address className="flex justify-center font-SOHNE_REGULAR">
                        {t("HOME_PAGE.FOOTER.EMAIL")}&nbsp;
                        <a href="mailto:info@joglekarkul.com">
                            info@joglekarkul.com
                        </a>
                    </address>
                    <address className="flex justify-center font-SOHNE_REGULAR">
                        {t("HOME_PAGE.FOOTER.CONTACT")}&nbsp;
                        <a href="tel:+91 9820290243">
                            +91 9820290243
                        </a>
                    </address>

                    <div className="flex justify-center space-x-10 md:space-x-3 p-1">
                        {footerNav.social.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-blue-800 hover:text-white"
                            >
                                <img
                                    src={item.icon}
                                    alt={item.name}
                                    className="h-6 w-6"
                                />
                                <span className="sr-only">
                                    {item.name}
                                </span>
                                {/* <item.icon className="h-6 w-6" aria-hidden="true" /> */}
                            </a>
                        ))}
                    </div>
                </div>
                {/* <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2001 CS Infocomm, Inc. All rights reserved.
          </p> */}
            </footer>
        </div>
    )
}

export default LandingPageScreen
