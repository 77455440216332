import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {GoogleOAuthProvider} from '@react-oauth/google';
import App from './App';
import AccountProvider from "./context/AccountProvider";
import NodeProvider from './context/NodeProvider';
import reportWebVitals from './reportWebVitals';
import './i18n';


// const I18nApp = () => {
//     useEffect(() => {
//         // With this method we dynamically load the catalogs
//         dynamicActivate(defaultLocale)
//     }, [])
//
//     return (
//         <I18nProvider i18n={i18n}>
//             <App/>
//         </I18nProvider>
//     )
// }

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="634576573457-c188vi7gi1j7dsva35d3tkpc2880pd98.apps.googleusercontent.com">
            <AccountProvider>
                <NodeProvider>
                    <App/>
                </NodeProvider>
            </AccountProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
