import React, {useContext, useEffect, useState} from 'react';
import {NodeContext} from '../../context/NodeProvider';
import {updateMember} from '../../service/memberApi';
import {translateTextToDevnagri} from '../../service/translateApi';
import {getOccupationList} from '../../service/occupationApi';
import {IN} from 'country-flag-icons/react/3x2';
import {SideDrawer} from "../../components/SideDrawer";
import {t} from "i18next";

export const EditNodeForm = (props) => {
    const states = [
        t("CONSTANTS.STATES.MAHARASHTRA"),
        t("CONSTANTS.STATES.ANDHRA_PRADESH"),
        t("CONSTANTS.STATES.ARUNACHAL_PRADESH"),
        t("CONSTANTS.STATES.ASSAM"),
        t("CONSTANTS.STATES.BIHAR"),
        t("CONSTANTS.STATES.CHHATTISGARH"),
        t("CONSTANTS.STATES.GOA"),
        t("CONSTANTS.STATES.GUJARAT"),
        t("CONSTANTS.STATES.HARYANA"),
        t("CONSTANTS.STATES.HIMACHAL_PRADESH"),
        t("CONSTANTS.STATES.JHARKHAND"),
        t("CONSTANTS.STATES.KARNATAKA"),
        t("CONSTANTS.STATES.KERALA"),
        t("CONSTANTS.STATES.MADHYA_PRADESH"),
        t("CONSTANTS.STATES.MANIPUR"),
        t("CONSTANTS.STATES.MEGHALAYA"),
        t("CONSTANTS.STATES.MIZORAM"),
        t("CONSTANTS.STATES.NAGALAND"),
        t("CONSTANTS.STATES.ODISHA"),
        t("CONSTANTS.STATES.PUNJAB"),
        t("CONSTANTS.STATES.RAJASTHAN"),
        t("CONSTANTS.STATES.SIKKIM"),
        t("CONSTANTS.STATES.TAMIL_NADU"),
        t("CONSTANTS.STATES.TELANGANA"),
        t("CONSTANTS.STATES.TRIPURA"),
        t("CONSTANTS.STATES.UTTAR_PRADESH"),
        t("CONSTANTS.STATES.UTTARAKHAND"),
        t("CONSTANTS.STATES.WEST_BENGAL"),
        t("CONSTANTS.STATES.ANDAMAN_AND_NICOBAR_ISLANDS"),
    ];
    const {selectedNode, setSelectedNode} = useContext(NodeContext);
    const [selectedState, setSelectedState] = useState(selectedNode?.data?.state || "");
    const [selectedCity, setSelectedCity] = useState(selectedNode?.data?.city || "");
    const [occupationList, setOccupationList] = useState([{}]);
    const [migrations, setMigrations] = useState(selectedNode?.data?.migrations || []);
    const [error, setError] = useState({});
    const [status, setStatus] = useState({type: "info", msg: 'No Change'}); // success, error, warning

    // useEffect(() => {
    //     setMigrations(selectedNode?.data?.migrations || []);
    //     console.log("Inside EditNodeForm");
    // }, [selectedNode?.data?.migrations]);

    const validate = async () => {
        let err = {};
        if (selectedNode?.firstName === '') {
            err['firstName'] = "First Name is required";
        }
        if (selectedNode?.fathersName === '') {
            err['fathersName'] = "Father's Name is required";
        }
        if (selectedNode?.data?.firstNameDevanagariScript === '') {
            err['firstNameDevanagariScript'] = "First Name in Devnagari is required";
        }
        if (selectedNode?.data?.fathersNameDevanagariScript === '') {
            err['fathersNameDevanagariScript'] = "Father's Name in Devnagari is required";
        }
        if (selectedNode?.dateOfBirth === '') {
            err['dateOfBirth'] = "Date of birth is required";
        }
        if (selectedNode?.state === '') {
            err['state'] = "State is required";
        }
        if (selectedNode?.city === '') {
            err['city'] = "City is required";
        }
        if (selectedNode?.occupation === '') {
            err['occupation'] = "Occupation is required";
        }

        return err;
    }

    const handleSaveChanges = async (e) => {
        const err = await validate();
        setError(err);
        console.log(err, selectedNode)
        if (Object.keys(err).length > 0) {
            e.preventDefault();
            return;
        }

        console.log(selectedNode);
        const updatedNode = updateMember(selectedNode?._id, {
            ...selectedNode,
            data: {
                ...selectedNode?.data,
                migrations: migrations
            }
        }).then((res) => {
            setMigrations([]);
            console.log("Update Member Response: ", res);
            setStatus({type: "info", msg: 'No Change'});
            setSelectedNode(null);
        }).catch((err) => {
            console.log(err);
            setStatus({type: "error", msg: 'Error Saving Changes!'});
        });
    };


    const handleOccupationData = (e) => {
        document.getElementById('occupation-dropdown').style.display = 'block';
        console.log(e.target.value);
        setSelectedNode({
            ...selectedNode,
            data: {
                ...selectedNode?.data,
                ['occupation']: e.target.value
            }
        });

        getOccupationList({word: e.target.value}).then((res) => {
            console.log(res);
            setOccupationList(res.occupations);
        });

    };

    const handleMigrationData = (e) => {
        const index = e.target.name.split('-')[1];
        const key = e.target.name.split('-')[0];
        // console.log(index, key, e.target.name);
        // console.log('Value: ', e.target.value);
        setMigrations(migrations.map((m, i) => {
                if (i === parseInt(index)) {
                    return {
                        ...m,
                        [key]: e.target.value
                    }
                }
                return m;
            }
        ));
        console.log(migrations);
    };

    const translateToDevnagri = (e) => {
        if (e.target.name === 'firstName' || e.target.name === 'middleName' || e.target.name === 'fathersName') {
            console.log("Translating to Devnagari");
            translateTextToDevnagri(e.target.value).then((res) => {
                console.log(res);
                setSelectedNode({
                    ...selectedNode,
                    data: {
                        ...selectedNode?.data,
                        [e.target.name + 'DevanagariScript']: res.result
                    }
                });
                setError({
                    ...error,
                    [e.target.name + 'DevanagariScript']: ""
                });
            })
        }

    };

    const displayOccupationAutocomplete = (flag) => {
        const element = document.getElementById('occupation-dropdown');
        if (element && flag) {
            element.style.display = 'block';
        } else if (element) {
            element.style.display = 'none';
        }
    };

    const handleFormData = (e) => {
        console.log(e.target.value, e.target.name);
        document.getElementById('occupation-dropdown').style.display = 'none';

        setSelectedNode({
            ...selectedNode,
            data: {
                ...selectedNode?.data,
                [e.target.name]: e.target.value
            }
        });

        if (e.target.name === 'occupation') console.log("Occupation Selected", e.target.value);

        setStatus({type: "warning", msg: 'Please Save Change!'});

        if (e.target.value !== "") {
            setError({
                ...error,
                [e.target.name]: ""
            });
        }

        if (e.target.value === "" && (e.target.name === 'firstName' || e.target.name === 'fathersName' || e.target.name === 'dateOfBirth' || e.target.name === 'state' || e.target.name === 'city' || e.target.name === 'occupation' || e.target.name === 'maritalStatus')) {
            setError({
                ...error,
                [e.target.name]: "This field is required"
            });
        }

        console.log(error);
    };

    useEffect(() => {
        console.log("EDIT NODE FORM RENDERED!");
    }, []);

    return (
        <SideDrawer
            show={props.showEditNodeForm}
            onBackDropClick={() => {
                console.log('Please fill the whole profile to continue!');
                props.setShowEditNodeForm(false);
            }}
        >
            <div className="no-scrollbar overflow-y-scroll px-[2vw] py-[2vh] bg-white">
                <form id="form" noValidate>
                    <h2 className="text-xl mb-3">
                        {t("EDIT_NODE_FORM.TITLE")}
                    </h2>

                    <fieldset className="mb-[15px] flex justify-between gap-2 flex-col md:flex-row lg:flex-row">
                        <div className="flex items-center gap-2">
                            <label
                                className="text-gray-600 w-[80px] text-right text-[15px]"
                                htmlFor="name"
                            >
                                <input
                                    type="checkbox"
                                    name='deceased'
                                    checked={selectedNode?.data?.deceased}
                                    onChange={handleFormData}
                                />
                                <span className="ml-2">
                                    {t("EDIT_NODE_FORM.ALIVE")}
                                </span>
                            </label>
                        </div>

                        <div className="flex items-center gap-2">
                            <label
                                className="text-gray-600 w-full text-right text-[15px]"
                                htmlFor="maritalStatus"
                            >
                                {t("EDIT_NODE_FORM.MARITAL_STATUS.LABEL")}
                            </label>
                            <select
                                id="maritalStatus"
                                className="text-violet11 shadow-violet7 border-b-2 border-gray-300 w-20 focus:shadow-violet8 inline-flex min-h-[35px] max-w-40 flex-1 items-center justify-center rounded-[4px] px-1 text-[15px] leading-none outline-none focus:border-blue-500"
                                onChange={handleFormData}
                                name="maritalStatus"
                                defaultValue={selectedNode?.data?.maritalStatus}
                                value={selectedNode?.data?.maritalStatus}
                            >
                                <option value="Single">
                                    {t("EDIT_NODE_FORM.MARITAL_STATUS.SINGLE")}
                                </option>
                                <option value="Married">
                                    {t("EDIT_NODE_FORM.MARITAL_STATUS.MARRIED")}
                                </option>
                                {/* <option value="Divorced">Divorced</option> */}
                                {/* <option value="Widowed">Widowed</option> */}
                            </select>
                        </div>
                        <div className="flex items-center gap-2">
                            <label className="text-gray-600 text-right text-[15px]" htmlFor="name">
                                {t("EDIT_NODE_FORM.GENDER.LABEL")}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="gender"
                                    value='Male'
                                    onClick={handleFormData}
                                    checked={selectedNode?.data?.gender === 'Male'}
                                    onChange={handleFormData}
                                    className="mr-2 text-black border-b-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                                />
                                {t("EDIT_NODE_FORM.GENDER.MALE")}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="gender"
                                    value='Female'
                                    checked={selectedNode?.data?.gender === 'Female'}
                                    onChange={handleFormData}
                                    onClick={handleFormData}
                                    className="mr-2 text-black border-b-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                                />
                                {t("EDIT_NODE_FORM.GENDER.FEMALE")}
                            </label>
                        </div>
                    </fieldset>
                    <fieldset className="mb-[15px] flex gap-2 flex-col md:flex-row lg:flex-row">
                        <div className="flex items-center gap-4">
                            <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="name">
                                <label className="text-gray-600 w-[6vw] text-right text-base" htmlFor="firstName">
                                    {t("EDIT_NODE_FORM.NAME.ENGLISH.FIRSTNAME.LABEL")}
                                    <p className='text-[10px]'>{t("EDIT_NODE_FORM.NAME.ENGLISH.FIRSTNAME.SUB_LABEL")}</p>
                                </label>
                            </label>
                            <div>
                                <input
                                    className="text-violet11 shadow-violet7 focus:shadow-violet8 inline-flex h-[35px] w-full border-b-2 border-gray-300 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none focus:border-blue-500"
                                    id="firstName"
                                    name='firstName'
                                    value={selectedNode?.data?.firstName || ""}
                                    defaultValue={selectedNode?.data?.firstName}
                                    onChange={handleFormData}
                                />
                                {
                                    error['firstName'] &&
                                    <p className="text-sm text-red-600" id="error">
                                        {error['firstName']}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="username">
                                Father's Name
                                <p className='text-[10px]'>(English)*</p>
                            </label>
                            <div>
                                <input
                                    className="text-violet11 shadow-violet7 inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                    id="fathersName"
                                    name='fathersName'
                                    value={selectedNode?.data?.fathersName}
                                    onChange={handleFormData}
                                />
                                {error['fathersName'] &&
                                    <p className="text-sm text-red-600" id="error">{error['fathersName']}</p>}
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="mb-[15px] flex gap-2 flex-col md:flex-row lg:flex-row">
                        <div className="flex items-center gap-4">
                            <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="name">
                                First Name
                                <p className='text-[10px]'>(Devnagri)*</p>
                            </label>
                            <input
                                className="text-violet11 inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                id="firstNameDevanagariScript"
                                name='firstNameDevanagariScript'
                                value={selectedNode?.data?.firstNameDevanagariScript || ""}
                                onChange={handleFormData}
                                onBlur={translateToDevnagri}
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="username">
                                Father's Name
                                <p className='text-[10px]'>(Devnagri)*</p>
                            </label>
                            <input
                                className="text-violet11 inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                id="fathersNameDevanagariScript"
                                name='fathersNameDevanagariScript'
                                value={selectedNode?.data?.fathersNameDevanagariScript || ""}
                                onChange={handleFormData}
                                onBlur={translateToDevnagri}
                            />
                        </div>
                    </fieldset>
                    {selectedNode?.data?.maritalStatus === 'Married' && selectedNode?.data?.gender === "Female" &&
                        <fieldset className="mb-[15px] flex gap-2 flex-col md:flex-row lg:flex-row">
                            <div className="flex items-center gap-4">
                                <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="name">
                                    First Name
                                    <p className='text-[10px]'>Before Marriage</p>
                                </label>
                                <input

                                    className="text-violet11 inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                    id="firstNameBeforeMarriage"
                                    name='firstNameBeforeMarriage'
                                    value={selectedNode?.data?.firstNameBeforeMarriage || ""}
                                    onChange={handleFormData}
                                    onBlur={translateToDevnagri}
                                />
                            </div>
                            <div className="flex items-center gap-4">
                                <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="username">
                                    Last Name
                                    <p className='text-[10px]'>Before Marriage</p>
                                </label>
                                <input

                                    className="text-violet11 inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                    id="lastNameBeforeMarriage"
                                    name='lastNameBeforeMarriage'
                                    value={selectedNode?.data?.lastNameBeforeMarriage || ""}
                                    onChange={handleFormData}
                                />
                            </div>
                        </fieldset>}
                    <fieldset className='mb-[15px] flex gap-2 flex-col md:flex-row lg:flex-row justify-around'>
                        <div className="flex items-center gap-4">
                            <label className='text-gray-600 w-[80px] text-right text-[15px]' htmlFor='dateOfBirth'>
                                Date Of Birth*
                            </label>
                            <div>
                                <input
                                    type="date"
                                    name='dateOfBirth'
                                    required
                                    value={selectedNode?.data?.dateOfBirth ? selectedNode?.data?.dateOfBirth.substring(0, 10) : ""}
                                    onChange={handleFormData}
                                    className="text-violet11 inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                />
                                {error['dateOfBirth'] &&
                                    <p className="text-sm text-red-600" id="error">{error['dateOfBirth']}</p>}
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <label className='text-gray-600 w-[80px] text-right text-[15px]' htmlFor='dateOfDeath'>
                                Date Of Death
                            </label>
                            <input
                                type="date"
                                name="dateOfDeath"
                                value={selectedNode?.data?.dateOfDeath ? selectedNode?.data?.dateOfDeath.substring(0, 10) : ""}
                                onChange={handleFormData}
                                className="text-violet11 inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                            />
                        </div>
                    </fieldset>
                    {/* phone */}
                    <fieldset className="mb-[15px] flex gap-2 flex-col md:flex-row lg:flex-row">
                        <div className="flex items-center gap-4 w-full">
                            <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="firstName">
                                Mobile
                            </label>
                            <div>
                                <div className="relative w-full flex">
                                    <button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone"
                                            className="text-violet11 inline-flex h-[35px] w-20 flex-1 items-center justify-center rounded-[4px]  text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                            type="button">
                                        <IN title="India" className="w-4 h-4 m-2"/>
                                        +91
                                        {/* <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" /></svg> */}
                                    </button>
                                    <div id="dropdown-phone" data-dropdown-content
                                         className="hidden z-10 absolute w-40 mt-2 py-1 bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-gray-700 dark:border-gray-600">
                                        <a href="#"
                                           className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600">+91</a>
                                    </div>
                                    <div className="relative w-full">
                                        <input
                                            type="text"
                                            id="phone-input"
                                            className="text-violet11 inline-flex min-h-[35px] max-h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                            placeholder="7734567890"
                                            value={selectedNode?.data?.phone || ""}
                                            onChange={handleFormData}
                                            name="phone"
                                            required
                                        />
                                    </div>
                                    {/* {error['phone'] && <p className="text-sm text-red-600" id="error">{error['phone']}</p>} */}
                                </div>
                            </div>
                        </div>
                        {/* pincode */}
                        <div className="flex items-center gap-4 w-full">
                            <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="name">
                                Pincode
                            </label>
                            <input
                                className="text-violet11 shadow-violet7 focus:shadow-violet8 inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                id="pincode"
                                name='pincode'
                                placeholder="6 digit pincode"
                                value={selectedNode?.data?.pincode || ""}
                                onChange={handleFormData}
                            />
                            {error['pincode'] && <p className="text-sm text-red-600" id="error">{error['pincode']}</p>}
                        </div>

                    </fieldset>
                    <fieldset className="mb-[15px] flex gap-2 flex-col md:flex-row lg:flex-row">
                        <div className="flex items-center gap-4 w-full">
                            <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="place">
                                Location
                                <p className='text-[10px]'>Current*</p>
                            </label>

                            {/* Country */}
                            <div className="flex gap-2 flex-wrap">
                                <button id="states-button" data-dropdown-toggle="dropdown-states"
                                        className="text-violet11 inline-flex h-[35px] w-[] items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none border-b-2 border-gray-300 focus:border-blue-500 "
                                        type="button">
                                    <IN title="India" className="w-4 h-4 "/>
                                    <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2" d="m1 1 4 4 4-4"/>
                                    </svg>
                                </button>

                                {/* States */}
                                <div>
                                    <select
                                        id="state"
                                        className="text-violet11 inline-flex max-h-[35px] min-h-[35px] max-w-40 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none border-b-2 border-gray-300 focus:border-blue-500 "
                                        onChange={handleFormData}
                                        name="state"
                                        value={selectedNode?.data?.state || ""}
                                    >
                                        <option className='text-gray-400' value="">Select State</option>
                                        {states.map((s) =>
                                            <option key={s} value={s}>{s}</option>
                                        )}
                                    </select>
                                    {error['state'] &&
                                        <span className="text-sm text-red-600" id="error">{error['state']}</span>}
                                </div>

                                {/* City */}
                                <div className="flex flex-col">
                                    <input
                                        className="text-violet11 inline-flex min-h-[35px] max-h-[35px] max-w-44 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500 "
                                        id="city"
                                        name='city'
                                        placeholder="City"
                                        value={selectedNode?.data?.city || ""}
                                        onChange={handleFormData}
                                    />
                                    {error['city'] &&
                                        <span className="text-sm text-red-600" id="error">{error['city']}</span>}
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="mb-[15px] flex items-center gap-2">
                        <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="address">
                            Address
                        </label>
                        <textarea id="description" rows="2"
                                  className="text-violet11 inline-flex w-full flex-1 items-center justify-center rounded-[4px] p-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                  placeholder="Flat No, Building Name, Street Name.."
                                  name='address'
                                  value={selectedNode?.data?.address || ""}
                                  onChange={handleFormData}
                        ></textarea>
                    </fieldset>
                    <fieldset className="mb-[15px] flex items-center gap-2">
                        <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="migrations">
                            Migrations
                            <p className='text-[10px]'>in order</p>
                        </label>
                        <div className="flex flex-col gap-2">
                            {migrations.map((migration, index) => {
                                return (
                                    <div key={index} className="flex gap-2">
                                        <div className="flex gap-2">
                                            {/*<select*/}
                                            {/*    id="country"*/}
                                            {/*    className="text-violet11 inline-flex h-[35px] w-20 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"*/}
                                            {/*    onChange={handleFormData}*/}
                                            {/*    name="country"*/}
                                            {/*    value={migration.country}*/}
                                            {/*>*/}
                                            {/*    {countries.map((country) => {*/}
                                            {/*        return <option key={country} value={country}>{country}</option>*/}
                                            {/*    })}*/}
                                            {/*</select>*/}
                                            <button id="states-button" data-dropdown-toggle="dropdown-states"
                                                    className="text-violet11 inline-flex h-[35px] w-[] items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none border-b-2 border-gray-300 focus:border-blue-500 "
                                                    type="button">
                                                <IN title="India" className="w-4 h-4 "/>
                                                <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2" d="m1 1 4 4 4-4"/>
                                                </svg>
                                            </button>
                                            <select
                                                id={'migrations-' + index + '-state'}
                                                className="text-violet11 inline-flex h-[35px] w-40 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                                onChange={handleMigrationData}
                                                name={'state-' + index}
                                                value={migration.state}
                                            >
                                                <option className='px-3 text-gray-400' value="">Select State
                                                </option>
                                                {states.map((s) =>
                                                    <option key={s} value={s}>{s}</option>
                                                )}
                                            </select>
                                            <input
                                                className="text-violet11 inline-flex h-[35px] w-40 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                                id={'migrations-' + index + '-city'}
                                                name={'city-' + index}
                                                placeholder="City"
                                                value={migration.city}
                                                onChange={handleMigrationData}
                                            />
                                            <button
                                                className="text-violet11 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[10px] font-medium leading-none bg-red-400 focus:outline-none"
                                                onClick={() => {
                                                    //   setSelectedNode({
                                                    //     ...selectedNode,
                                                    //     data: {
                                                    //       ...selectedNode?.data,
                                                    //       migrations: selectedNode?.data?.migrations.filter((m, i) => i !== index)
                                                    //     }
                                                    //   });
                                                    // }
                                                    setMigrations(migrations.filter((m, i) => i !== index))
                                                }}
                                            > -
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                            <button
                                className="text-violet11 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[10px] font-medium leading-none focus:shadow-[0_0_0_2px] focus:outline-none"
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        console.log("Add Location Clicked!");
                                        // setSelectedNode({
                                        //   ...selectedNode,
                                        //   data: {
                                        //     ...selectedNode?.data,
                                        //     migrations: [...selectedNode?.data.migrations, { country: "", state: "", city: "" }]
                                        //   }
                                        // });
                                        setMigrations([...migrations, {country: "", state: "", city: ""}]);
                                    }
                                }
                            >
                                + location
                            </button>
                        </div>
                    </fieldset>

                    <fieldset className="mb-[15px] flex items-center gap-2">
                        <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="occupation">
                            Occupation*
                        </label>
                        <div className="relative inline-block w-full">
                            <input
                                id="dropdown-button"
                                type="text"
                                data-dropdown-toggle="dropdown"
                                className="text-violet11 inline-flex w-full h-[35px] flex-1 items-center justify-center rounded-[4px] p-[10px] text-[15px] leading-none outline-none border-b-2 border-gray-300 focus:border-blue-500"
                                placeholder="Enter the occupation of the person..."
                                name='occupationText'
                                onFocus={() => displayOccupationAutocomplete(true)}
                                onBlur={() => displayOccupationAutocomplete(false)}
                                value={selectedNode?.data?.occupation || ""}
                                onChange={handleOccupationData}
                            />
                            {error['occupation'] &&
                                <p className="text-sm text-red-600" id="error">{error['occupation']}</p>}
                            <div id="occupation-dropdown"
                                 className="hidden z-40 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700">
                                <ul className="py-2 text-sm text-gray-700 overflow-scroll dark:text-gray-200 max-h-40 w-auto"
                                    aria-labelledby="dropdown-button">{
                                    occupationList.map((occupation) => {
                                        return <li key={occupation._id}>
                                            <button key={occupation._id} type="button"

                                                    name='occupation'
                                                    value={occupation.OccupationName}
                                                    onClick={handleFormData}
                                                    className="inline-flex w-full px-4 py-2  hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                {occupation.OccupationName}
                                            </button>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mb-[15px] flex items-center gap-2">
                        <label className="text-gray-600 w-[80px] text-right text-[15px]" htmlFor="name">
                            Bio
                        </label>
                        <textarea id="description" rows="4"
                                  className="text-violet11 inline-flex w-full flex-1 items-center justify-center rounded-[4px] p-[10px] text-[15px] leading-none outline-none border-2 border-gray-300 focus:border-blue-500 focus:shadow-black"
                                  placeholder="Write the story of that person's life, including the supporting detail..."
                                  name='biography'
                                  value={selectedNode?.data?.biography || ""}
                                  onChange={handleFormData}
                        ></textarea>
                    </fieldset>
                    <div className="mt-[25px] flex justify-end">
                        {status.type === "info" ?
                            <span className="text-blue-600 text-sm align-middle ">{status.msg}</span>
                            : (status.type === "error") ?
                                <span className="text-red-600 text-sm align-middle">{status.msg}</span>
                                : (status.type === "success") ?
                                    <span className="text-green-600 text-sm align-middle">{status.msg}</span>
                                    : (status.type === "warning") &&
                                    <span className="text-yellow-600 text-sm align-middle leading-4">{status.msg}</span>
                        }
                        <button onClick={handleSaveChanges}>
                            <button
                                className="bg-green4 text-green11 hover:bg-green5 focus:shadow-green7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none focus:shadow-[0_0_0_2px] focus:outline-none">
                                Save changes
                            </button>
                        </button>
                    </div>
                    {/*<Dialog.Close asChild>*/}
                    {/*    <button*/}
                    {/*        className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"*/}
                    {/*        aria-label="Close"*/}
                    {/*    >*/}
                    {/*        <Cross2Icon/>*/}
                    {/*    </button>*/}
                    {/*</Dialog.Close>*/}
                </form>
            </div>
        </SideDrawer>
    );
}