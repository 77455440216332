import axios from 'axios';

import {BASE_URL} from '../utils/config'
const URL = BASE_URL;//"http://localhost:3001";

export const getOccupationList = async (word) => {
    try {
        const response = await axios.get(`${URL}/occupation`, {params: word});
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error) {
        console.error(error);
    }
}