import React, {useState} from "react";
import {AccountContext} from "../context/AccountProvider";
import Cookies from 'universal-cookie';
import {t} from "i18next";
import FlyoutMenu from "../components/FlyoutMenu";
import {Link} from "react-router-dom";

const Header = () => {
    const cookies = new Cookies();
    const {account} = React.useContext(AccountContext);
    const [locale, setLocale] = useState(cookies.get("locale") || "en");

    const handleLanguageDropdown = (e) => {
        console.log("e.target.value: ", e.target.value)
        cookies.set("locale", e.target.value, {path: "/"})
        window.location.reload();
    }

    return (
        <>
            <nav
                className="h-[4vw] z-10 rounded-full fixed inset-x-[1vw] top-[1vw] flex items-center justify-between bg-white shadow-lg pl-[1vw] pr-[1vh] py-[1vh]"
                aria-label="Global"
            >
                <div className="flex grow-0">
                    <a href="#" key="logo" className="-m-1.5 p-1.5 flex items-center">
                        <img
                            className="h-[3vw] w-auto px-[1vw]"
                            src="landing/ganesh_logo_image.png"
                            alt="Joglekarkul Logo"
                        />
                        <h1 key="logo-name" className={"font-SOHNE_BOLD"}>
                            {t("HEADER.JOGLEKARKUL")}
                        </h1>
                    </a>
                </div>
                <div className="flex gap-x-12">
                    <Link
                        to="/"
                        key="Home"
                        className="text-md flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        {t("HEADER.HOME")}
                    </Link>
                    <Link
                        to="/vansh-veli"
                        key="VanshVeli"
                        className="text-md flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >

                        {t("HEADER.VANSH_VELI")}
                    </Link>
                    <Link
                        to="/info"
                        key="Information"
                        className="text-md flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        {t("HEADER.INFORMATION")}
                    </Link>
                    <Link
                        to="/dashboard"
                        key="Dashboard"
                        className="text-md flex items-center gap-2 font-SOHNE_MEDIUM leading-6 text-gray-900"
                    >
                        {t("HEADER.DASHBOARD")}
                    </Link>
                    <FlyoutMenu/>
                </div>
                <div className="flex items-center gap-x-[2vw]">
                    {/* Language Selection */}
                    <div className={"flex flex-row"}>
                        <select
                            value={locale}
                            onChange={(e) => handleLanguageDropdown(e)}
                            id="small"
                            className="text-sm text-gray-900 rounded-lg ring-0"
                        >
                            <option value="en">English</option>
                            <option value="mr">मराठी</option>
                        </select>
                    </div>

                    <Link
                        className="lg:flex items-center"
                        to={'/'}
                    >
                        {
                            account.imageUrl ? (
                                account.imageUrl?.length <= 2
                                    ? (
                                        <div
                                            className={`z-10 w-10 h-10 rounded-full overflow-hidden flex items-center justify-center ${account.imageUrlBg}`}
                                        >
                                            <p className={"text-white font-SOHNE_MONO_BOLD"}>
                                                {account.imageUrl.toUpperCase()}
                                            </p>

                                        </div>
                                    ) : (
                                        <img
                                            className="w-10 h-10 rounded-full"
                                            src={account.imageUrl}
                                            alt="User Profile Image"
                                            referrerPolicy={"no-referrer"}
                                        />
                                    )
                            ) : (
                                <img
                                    className="w-10 h-10 rounded-full bg-[#D26A53]"
                                    src={'assets/default-pfp.png'}
                                    alt="Default PFP"
                                    referrerPolicy={"no-referrer"}
                                />
                            )
                        }
                        {/*<p className="hidden lg:flex lg:flex-1 lg:justify-end text-sm font-medium dark:text-white leading-6 text-gray-900 p-1.5">*/}
                        {/*    {account.firstName + ' ' + (account.lastName ? account.lastName : '')}*/}
                        {/*</p>*/}
                    </Link>
                </div>
            </nav>
        </>
    )
}
export default Header;