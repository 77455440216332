import React, {useState,} from "react";
import {useReactFlow} from "reactflow";
import {addRelation} from "../../service/relationApi";
import {relationHandle} from "../../utils/relation";
import {t} from "i18next";

export const AddEdgeForm = (props) => {
    const instance = useReactFlow();

    const [error, setError] = useState({});
    const [relation, setRelation] = useState({
        A: '',
        B: '',
        DetailA: '',
        DetailB: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Edge Added");
        const edge = props.currentEdge;
        const relationA = {
            id: `${edge.params.source}-${edge.params.target}`,
            source: edge.params.source,
            target: edge.params.target,
            sourceHandle: relationHandle[relation.A].sourceHandle,
            targetHandle: relationHandle[relation.A].targetHandle,
            relationshipType: relation.A,
            relationshipDetails: relation.DetailA,
            type: "relation",
            markerEnd: {type: 'arrow', color: 'green', width: 20, height: 20},
            label: relation.A,
        }
        const relationB = {
            id: `${edge.params.target}-${edge.params.source}`,
            source: edge.params.target,
            target: edge.params.source,
            sourceHandle: relationHandle[relation.B].sourceHandle,
            targetHandle: relationHandle[relation.B].targetHandle,
            relationshipType: relation.B,
            relationshipDetails: relation.DetailB,
            type: "relation",
            markerEnd: {type: 'arrow', color: 'green', width: 20, height: 20},
            label: relation.B,
        }

        if ((relation.A === "brother" && relation.B === "brother") || (relation.A === "sister" && relation.B === "sister")) {
            relationB.sourceHandle = relationHandle[relation.B].targetHandle;
            relationB.targetHandle = relationHandle[relation.B].sourceHandle;
        }

        addRelation(relationA).then((res) => {
            console.log('Relation A Result: ', res);
            if (res) {
                addRelation(relationB).then((res2) => {
                    console.log('Relation B Result: ', res2);
                    instance.setEdges((edges) => ([
                        ...edges,
                        relationA,
                        relationB
                    ]))
                });
            }
        });
        props.setEdgeFormToggle(false);
    }

    const handleFormData = (e) => {
        const {name, value} = e.target;
        console.log("name: ", name, "value: ", value);
        setRelation({...relation, [name]: value});
        if ((name === 'B' || name === 'A') && value === "") {
            setError({...error, [name]: 'Please select a relation'});
        } else if (name === 'B' || name === 'A') {
            setError({...error, [e.target.name]: ''})
        }
        console.log("relation: ", relation);
        console.log("error: ", error);
    }
    return (
        <div
            className="fixed h-screen w-screen z-50 flex items-center justify-center bg-smoke-light backdrop-grayscale-[.5] bg-black/30 ">
            <div className="fixed border-0 shadow-lg sm:rounded-3xl max-w-sm bg-white p-6">
                <form id="form" noValidate>
                    <h2 className="text-xl mb-3">
                        {t("ADD_EDGE_FORM.TITLE")}
                    </h2>
                    <div className="flex">
                        <div>
                            <label
                                htmlFor="relation"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                {/*{props.currentEdge.sourceData.data.firstName} is:*/}
                                {t("ADD_EDGE_FORM.RELATION.A.LABEL", {
                                    firstName: props.currentEdge.sourceData.data.firstName
                                })}
                            </label>
                            <select
                                value={relation.A} onChange={handleFormData}
                                id="relationshipType"
                                name="A"
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option className="block py-1 px-0 " value="">
                                    {t("ADD_EDGE_FORM.RELATION.A.PLACEHOLDER")}
                                </option>
                                <option value="brother">
                                    {t("CONSTANTS.RELATIONSHIP.brother")}
                                </option>
                                <option value="sister">
                                    {t("CONSTANTS.RELATIONSHIP.sister")}
                                </option>
                                <option value="father">
                                    {t("CONSTANTS.RELATIONSHIP.father")}
                                </option>
                                <option value="mother">
                                    {t("CONSTANTS.RELATIONSHIP.mother")}
                                </option>
                                <option value="son">
                                    {t("CONSTANTS.RELATIONSHIP.son")}
                                </option>
                                <option value="daughter">
                                    {t("CONSTANTS.RELATIONSHIP.daughter")}
                                </option>
                                <option value="father-in-law">
                                    {t("CONSTANTS.RELATIONSHIP.father-in-law")}
                                </option>
                                <option value="mother-in-law">
                                    {t("CONSTANTS.RELATIONSHIP.mother-in-law")}
                                </option>
                                <option value="son-in-law">
                                    {t("CONSTANTS.RELATIONSHIP.son-in-law")}
                                </option>
                                <option value="daughter-in-law">
                                    {t("CONSTANTS.RELATIONSHIP.daughter-in-law")}
                                </option>
                                <option value="husband">
                                    {t("CONSTANTS.RELATIONSHIP.husband")}
                                </option>
                                <option value="wife">
                                    {t("CONSTANTS.RELATIONSHIP.wife")}
                                </option>
                                <option value="adopted-son">
                                    {t("CONSTANTS.RELATIONSHIP.adopted-son")}
                                </option>
                                <option value="adopted-daughter">
                                    {t("CONSTANTS.RELATIONSHIP.adopted-daughter")}
                                </option>
                            </select>
                            {
                                error.A &&
                                <p className="text-red-500 text-xs italic">
                                    {error.A}
                                </p>
                            }

                            {/* bio */}
                            <div className="my-2">
                                <label
                                    htmlFor="description"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    {t("ADD_EDGE_FORM.DETAIL_LABEL")}
                                </label>
                                <textarea
                                    id="relationshipDetails"
                                    rows="2"
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    name='DetailA'
                                    value={relation.DetailA}
                                    onChange={handleFormData}
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor="relation"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                {/*{props.currentEdge.targetData.data.firstName} is:*/}
                                {t("ADD_EDGE_FORM.RELATION.B.LABEL", {
                                    firstName: props.currentEdge.targetData.data.firstName
                                })}
                            </label>
                            <select
                                value={relation.B}
                                onChange={handleFormData}
                                id="relationshipType"
                                name="B"
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option className="block py-1 px-0 " value="">
                                    {t("ADD_EDGE_FORM.RELATION.B.PLACEHOLDER")}
                                </option>
                                <option value="brother">
                                    {t("CONSTANTS.RELATIONSHIP.brother")}
                                </option>
                                <option value="sister">
                                    {t("CONSTANTS.RELATIONSHIP.sister")}
                                </option>
                                <option value="father">
                                    {t("CONSTANTS.RELATIONSHIP.father")}
                                </option>
                                <option value="mother">
                                    {t("CONSTANTS.RELATIONSHIP.mother")}
                                </option>
                                <option value="son">
                                    {t("CONSTANTS.RELATIONSHIP.son")}
                                </option>
                                <option value="daughter">
                                    {t("CONSTANTS.RELATIONSHIP.daughter")}
                                </option>
                                <option value="father-in-law">
                                    {t("CONSTANTS.RELATIONSHIP.father-in-law")}
                                </option>
                                <option value="mother-in-law">
                                    {t("CONSTANTS.RELATIONSHIP.mother-in-law")}
                                </option>
                                <option value="son-in-law">
                                    {t("CONSTANTS.RELATIONSHIP.son-in-law")}
                                </option>
                                <option value="daughter-in-law">
                                    {t("CONSTANTS.RELATIONSHIP.daughter-in-law")}
                                </option>
                                <option value="husband">
                                    {t("CONSTANTS.RELATIONSHIP.husband")}
                                </option>
                                <option value="wife">
                                    {t("CONSTANTS.RELATIONSHIP.wife")}
                                </option>
                                <option value="adopted-son">
                                    {t("CONSTANTS.RELATIONSHIP.adopted-son")}
                                </option>
                                <option value="adopted-daughter">
                                    {t("CONSTANTS.RELATIONSHIP.adopted-daughter")}
                                </option>
                            </select>
                            {error.B &&
                                <p className="text-red-500 text-xs italic">
                                    {error.B}
                                </p>
                            }

                            {/* bio */}
                            <div className="my-2">
                                <label
                                    htmlFor="description"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    {t("ADD_EDGE_FORM.DETAIL_LABEL")}
                                </label>
                                <textarea
                                    rows="2"
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    name='DetailB'
                                    value={relation.DetailB}
                                    onChange={handleFormData}
                                />
                            </div>
                        </div>
                    </div>

                    {/* addNode Button */}
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="text-white justify-center flex items-center bg-blue-700 hover:bg-blue-800 w-full focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                        Create Relation
                    </button>

                    <button
                        onClick={() => props.setEdgeFormToggle(false)}
                        type="button"
                        data-drawer-hide="drawer-form"
                        aria-controls="drawer-form"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close menu</span>
                    </button>
                </form>
            </div>
        </div>
    );
}
