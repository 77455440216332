import axios from 'axios';

import {BASE_URL} from '../utils/config'
const URL = BASE_URL;//"http://localhost:3001";

export const translateTextToDevnagri = async (text) => {
    try {
        console.log("(translateApi) Given text for translation: ", text);
        const response = await axios.get(`${URL}/translate?text=${text}`);
        return response.data;
    } catch (error) {
        console.error("(translateApi) Error while translating to Devnagari:", error);
    }
}