import {Transition} from "@headlessui/react";
import {Fragment} from "react";

export const SideDrawer = ({children, show, onBackDropClick}) => {
    return (
        <>
            <Transition
                show={show}
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-[50vw]"
                leave="transform transition ease-in-out duration-500 delay-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
            >
                <div className={"z-20 fixed inset-0 bg-black/50"} onClick={onBackDropClick}/>
            </Transition>

            <Transition
                show={show}
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-[50vw]"
                leave="transform transition ease-in-out duration-500 delay-100"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
            >
                <div className="z-50 fixed inset-y-0 right-0 w-fit overflow-scroll no-scrollbar bg-white">
                    {children}
                </div>
            </Transition>
        </>
    );
}