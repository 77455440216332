export const navigation = [
    {name: "Home", href: "joglekarkul"},
    {name: "Vansh Veli", href: "vansh-veli"},
    {name: "Information", href: "info"},
    {name: "Dashboard", href: "dashboard"},
    // {name: "Coming soon", href: "#"},
]

export const footerNav = {
    social: [
        {
            name: "Facebook",
            href: "/",
            icon: "landing/facebook.png"
        },
        {
            name: "Instagram",
            href: "/",
            icon: "landing/insta.png"
        },
        {
            name: "X",
            href: "/",
            icon: "landing/tweeter.png"
        },
        {
            name: "YouTube",
            href: "/",
            icon: "landing/youtube.png"
        },
    ],
}
