export const countryCodes = [
    {
        countryCode: "AD",
        countryFlagSrc: "assets/country_flags/AD.png",
        countryDialingCode: "+376",
        countryName: "Andorra",
    },
    {
        countryCode: "AE",
        countryFlagSrc: "assets/country_flags/AE.png",
        countryDialingCode: "+971",
        countryName: "United Arab Emirates",
    },
    {
        countryCode: "AF",
        countryFlagSrc: "assets/country_flags/AF.png",
        countryDialingCode: "+93",
        countryName: "Afghanistan",
    },
    {
        countryCode: "AG",
        countryFlagSrc: "assets/country_flags/AG.png",
        countryDialingCode: "+1268",
        countryName: "Antigua and Barbuda",
    },
    {
        countryCode: "AL",
        countryFlagSrc: "assets/country_flags/AL.png",
        countryDialingCode: "+355",
        countryName: "Albania",
    },
    {
        countryCode: "AM",
        countryFlagSrc: "assets/country_flags/AM.png",
        countryDialingCode: "+374",
        countryName: "Armenia",
    },
    {
        countryCode: "AO",
        countryFlagSrc: "assets/country_flags/AO.png",
        countryDialingCode: "+244",
        countryName: "Angola",
    },
    {
        countryCode: "AR",
        countryFlagSrc: "assets/country_flags/AR.png",
        countryDialingCode: "+54",
        countryName: "Argentina",
    },
    {
        countryCode: "AS",
        countryFlagSrc: "assets/country_flags/AS.png",
        countryDialingCode: "+1 684",
        countryName: "American Samoa",
    },
    {
        countryCode: "AT",
        countryFlagSrc: "assets/country_flags/AT.png",
        countryDialingCode: "+43",
        countryName: "Austria",
    },
    {
        countryCode: "AU",
        countryFlagSrc: "assets/country_flags/AU.png",
        countryDialingCode: "+61",
        countryName: "Australia",
    },
    {
        countryCode: "AW",
        countryFlagSrc: "assets/country_flags/AW.png",
        countryDialingCode: "+297",
        countryName: "Aruba",
    },
    {
        countryCode: "AZ",
        countryFlagSrc: "assets/country_flags/AZ.png",
        countryDialingCode: "+994",
        countryName: "Azerbaijan",
    },
    {
        countryCode: "BA",
        countryFlagSrc: "assets/country_flags/BA.png",
        countryDialingCode: "+387",
        countryName: "Bosnia and Herzegovina",
    },
    {
        countryCode: "BB",
        countryFlagSrc: "assets/country_flags/BB.png",
        countryDialingCode: "+1 246",
        countryName: "Barbados",
    },
    {
        countryCode: "BD",
        countryFlagSrc: "assets/country_flags/BD.png",
        countryDialingCode: "+880",
        countryName: "Bangladesh",
    },
    {
        countryCode: "BE",
        countryFlagSrc: "assets/country_flags/BE.png",
        countryDialingCode: "+32",
        countryName: "Belgium",
    },
    {
        countryCode: "BF",
        countryFlagSrc: "assets/country_flags/BF.png",
        countryDialingCode: "+226",
        countryName: "Burkina Faso",
    },
    {
        countryCode: "BG",
        countryFlagSrc: "assets/country_flags/BG.png",
        countryDialingCode: "+359",
        countryName: "Bulgaria",
    },
    {
        countryCode: "BJ",
        countryFlagSrc: "assets/country_flags/BJ.png",
        countryDialingCode: "+229",
        countryName: "Benin",
    },
    {
        countryCode: "BM",
        countryFlagSrc: "assets/country_flags/BM.png",
        countryDialingCode: "+1 441",
        countryName: "Bermuda",
    },
    {
        countryCode: "BN",
        countryFlagSrc: "assets/country_flags/BN.png",
        countryDialingCode: "+673",
        countryName: "Brunei Darussalam",
    },
    {
        countryCode: "BO",
        countryFlagSrc: "assets/country_flags/BO.png",
        countryDialingCode: "+591",
        countryName: "Bolivia",
    },
    {
        countryCode: "BR",
        countryFlagSrc: "assets/country_flags/BR.png",
        countryDialingCode: "+55",
        countryName: "Brazil",
    },
    {
        countryCode: "BS",
        countryFlagSrc: "assets/country_flags/BS.png",
        countryDialingCode: "+1 242",
        countryName: "Bahamas",
    },
    {
        countryCode: "BT",
        countryFlagSrc: "assets/country_flags/BT.png",
        countryDialingCode: "+975",
        countryName: "Bhutan",
    },
    {
        countryCode: "BW",
        countryFlagSrc: "assets/country_flags/BW.png",
        countryDialingCode: "+267",
        countryName: "Botswana",
    },
    {
        countryCode: "BY",
        countryFlagSrc: "assets/country_flags/BY.png",
        countryDialingCode: "+375",
        countryName: "Belarus",
    },
    {
        countryCode: "BZ",
        countryFlagSrc: "assets/country_flags/BZ.png",
        countryDialingCode: "+501",
        countryName: "Belize",
    },
    {
        countryCode: "CA",
        countryFlagSrc: "assets/country_flags/CA.png",
        countryDialingCode: "+1",
        countryName: "Canada",
    },
    {
        countryCode: "CD",
        countryFlagSrc: "assets/country_flags/CD.png",
        countryDialingCode: "+243",
        countryName: "Congo (Kinshasa)",
    },
    {
        countryCode: "CF",
        countryFlagSrc: "assets/country_flags/CF.png",
        countryDialingCode: "+236",
        countryName: "Central African Republic",
    },
    {
        countryCode: "CG",
        countryFlagSrc: "assets/country_flags/CG.png",
        countryDialingCode: "+242",
        countryName: "Congo (Brazzaville)",
    },
    {
        countryCode: "CH",
        countryFlagSrc: "assets/country_flags/CH.png",
        countryDialingCode: "+41",
        countryName: "Switzerland",
    },
    {
        countryCode: "CI",
        countryFlagSrc: "assets/country_flags/CI.png",
        countryDialingCode: "+225",
        countryName: "Côte d'Ivoire",
    },
    {
        countryCode: "CK",
        countryFlagSrc: "assets/country_flags/CK.png",
        countryDialingCode: "+682",
        countryName: "Cook Islands",
    },
    {
        countryCode: "CL",
        countryFlagSrc: "assets/country_flags/CL.png",
        countryDialingCode: "+56",
        countryName: "Chile",
    },
    {
        countryCode: "CM",
        countryFlagSrc: "assets/country_flags/CM.png",
        countryDialingCode: "+237",
        countryName: "Cameroon",
    },
    {
        countryCode: "CO",
        countryFlagSrc: "assets/country_flags/CO.png",
        countryDialingCode: "+57",
        countryName: "Colombia",
    },
    {
        countryCode: "CR",
        countryFlagSrc: "assets/country_flags/CR.png",
        countryDialingCode: "+506",
        countryName: "Costa Rica",
    },
    {
        countryCode: "CV",
        countryFlagSrc: "assets/country_flags/CV.png",
        countryDialingCode: "+238",
        countryName: "Cape Verde",
    },
    {
        countryCode: "CW",
        countryFlagSrc: "assets/country_flags/CW.png",
        countryDialingCode: "+599",
        countryName: "Curaçao",
    },
    {
        countryCode: "CY",
        countryFlagSrc: "assets/country_flags/CY.png",
        countryDialingCode: "+537",
        countryName: "Cyprus",
    },
    {
        countryCode: "CZ",
        countryFlagSrc: "assets/country_flags/CZ.png",
        countryDialingCode: "+420",
        countryName: "Czech Republic",
    },
    {
        countryCode: "DE",
        countryFlagSrc: "assets/country_flags/DE.png",
        countryDialingCode: "+49",
        countryName: "Germany",
    },
    {
        countryCode: "DJ",
        countryFlagSrc: "assets/country_flags/DJ.png",
        countryDialingCode: "+253",
        countryName: "Djibouti",
    },
    {
        countryCode: "DK",
        countryFlagSrc: "assets/country_flags/DK.png",
        countryDialingCode: "+45",
        countryName: "Denmark",
    },
    {
        countryCode: "DM",
        countryFlagSrc: "assets/country_flags/DM.png",
        countryDialingCode: "+1 767",
        countryName: "Dominica",
    },
    {
        countryCode: "DO",
        countryFlagSrc: "assets/country_flags/DO.png",
        countryDialingCode: "+1 849",
        countryName: "Dominican Republic",
    },
    {
        countryCode: "DZ",
        countryFlagSrc: "assets/country_flags/DZ.png",
        countryDialingCode: "+213",
        countryName: "Algeria",
    },
    {
        countryCode: "EC",
        countryFlagSrc: "assets/country_flags/EC.png",
        countryDialingCode: "+593",
        countryName: "Ecuador",
    },
    {
        countryCode: "EG",
        countryFlagSrc: "assets/country_flags/EG.png",
        countryDialingCode: "+20",
        countryName: "Egypt",
    },
    {
        countryCode: "ES",
        countryFlagSrc: "assets/country_flags/ES.png",
        countryDialingCode: "+34",
        countryName: "Spain",
    },
    {
        countryCode: "ET",
        countryFlagSrc: "assets/country_flags/ET.png",
        countryDialingCode: "+251",
        countryName: "Ethiopia",
    },
    {
        countryCode: "FI",
        countryFlagSrc: "assets/country_flags/FI.png",
        countryDialingCode: "+358",
        countryName: "Finland",
    },
    {
        countryCode: "FJ",
        countryFlagSrc: "assets/country_flags/FJ.png",
        countryDialingCode: "+679",
        countryName: "Fiji",
    },
    {
        countryCode: "FK",
        countryFlagSrc: "assets/country_flags/FK.png",
        countryDialingCode: "+500",
        countryName: "Falkland Islands (Malvinas)",
    },
    {
        countryCode: "FM",
        countryFlagSrc: "assets/country_flags/FM.png",
        countryDialingCode: "+691",
        countryName: "Micronesia, Federated States of",
    },
    {
        countryCode: "FO",
        countryFlagSrc: "assets/country_flags/FO.png",
        countryDialingCode: "+298",
        countryName: "Faroe Islands",
    },
    {
        countryCode: "FR",
        countryFlagSrc: "assets/country_flags/FR.png",
        countryDialingCode: "+33",
        countryName: "France",
    },
    {
        countryCode: "GA",
        countryFlagSrc: "assets/country_flags/GA.png",
        countryDialingCode: "+241",
        countryName: "Gabon",
    },
    {
        countryCode: "GB",
        countryFlagSrc: "assets/country_flags/GB.png",
        countryDialingCode: "+44",
        countryName: "United Kingdom",
    },
    {
        countryCode: "GD",
        countryFlagSrc: "assets/country_flags/GD.png",
        countryDialingCode: "+1 473",
        countryName: "Grenada",
    },
    {
        countryCode: "GE",
        countryFlagSrc: "assets/country_flags/GE.png",
        countryDialingCode: "+995",
        countryName: "Georgia",
    },
    {
        countryCode: "GF",
        countryFlagSrc: "assets/country_flags/GF.png",
        countryDialingCode: "+594",
        countryName: "French Guiana",
    },
    {
        countryCode: "GG",
        countryFlagSrc: "assets/country_flags/GG.png",
        countryDialingCode: "+44",
        countryName: "Guernsey",
    },
    {
        countryCode: "GH",
        countryFlagSrc: "assets/country_flags/GH.png",
        countryDialingCode: "+233",
        countryName: "Ghana",
    },
    {
        countryCode: "GI",
        countryFlagSrc: "assets/country_flags/GI.png",
        countryDialingCode: "+350",
        countryName: "Gibraltar",
    },
    {
        countryCode: "GL",
        countryFlagSrc: "assets/country_flags/GL.png",
        countryDialingCode: "+299",
        countryName: "Greenland",
    },
    {
        countryCode: "GM",
        countryFlagSrc: "assets/country_flags/GM.png",
        countryDialingCode: "+220",
        countryName: "Gambia",
    },
    {
        countryCode: "GP",
        countryFlagSrc: "assets/country_flags/GP.png",
        countryDialingCode: "+590",
        countryName: "Guadeloupe",
    },
    {
        countryCode: "GQ",
        countryFlagSrc: "assets/country_flags/GQ.png",
        countryDialingCode: "+240",
        countryName: "Equatorial Guinea",
    },
    {
        countryCode: "GR",
        countryFlagSrc: "assets/country_flags/GR.png",
        countryDialingCode: "+30",
        countryName: "Greece",
    },
    {
        countryCode: "GT",
        countryFlagSrc: "assets/country_flags/GT.png",
        countryDialingCode: "+502",
        countryName: "Guatemala",
    },
    {
        countryCode: "GY",
        countryFlagSrc: "assets/country_flags/GY.png",
        countryDialingCode: "+595",
        countryName: "Guyana",
    },
    {
        countryCode: "HK",
        countryFlagSrc: "assets/country_flags/HK.png",
        countryDialingCode: "+852",
        countryName: "Hong Kong, SAR China",
    },
    {
        countryCode: "HN",
        countryFlagSrc: "assets/country_flags/HN.png",
        countryDialingCode: "+504",
        countryName: "Honduras",
    },
    {
        countryCode: "HR",
        countryFlagSrc: "assets/country_flags/HR.png",
        countryDialingCode: "+385",
        countryName: "Croatia",
    },
    {
        countryCode: "HT",
        countryFlagSrc: "assets/country_flags/HT.png",
        countryDialingCode: "+509",
        countryName: "Haiti",
    },
    {
        countryCode: "HU",
        countryFlagSrc: "assets/country_flags/HU.png",
        countryDialingCode: "+36",
        countryName: "Hungary",
    },
    {
        countryCode: "ID",
        countryFlagSrc: "assets/country_flags/ID.png",
        countryDialingCode: "+62",
        countryName: "Indonesia",
    },
    {
        countryCode: "IE",
        countryFlagSrc: "assets/country_flags/IE.png",
        countryDialingCode: "+353",
        countryName: "Ireland",
    },
    {
        countryCode: "IL",
        countryFlagSrc: "assets/country_flags/IL.png",
        countryDialingCode: "+972",
        countryName: "Israel",
    },
    {
        countryCode: "IM",
        countryFlagSrc: "assets/country_flags/IM.png",
        countryDialingCode: "+44",
        countryName: "Isle of Man",
    },
    {
        countryCode: "IN",
        countryFlagSrc: "assets/country_flags/IN.png",
        countryDialingCode: "+91",
        countryName: "India",
    },
    {
        countryCode: "IQ",
        countryFlagSrc: "assets/country_flags/IQ.png",
        countryDialingCode: "+964",
        countryName: "Iraq",
    },
    {
        countryCode: "IT",
        countryFlagSrc: "assets/country_flags/IT.png",
        countryDialingCode: "+39",
        countryName: "Italy",
    },
    {
        countryCode: "JE",
        countryFlagSrc: "assets/country_flags/JE.png",
        countryDialingCode: "+44",
        countryName: "Jersey",
    },
    {
        countryCode: "JM",
        countryFlagSrc: "assets/country_flags/JM.png",
        countryDialingCode: "+1 876",
        countryName: "Jamaica",
    },
    {
        countryCode: "JO",
        countryFlagSrc: "assets/country_flags/JO.png",
        countryDialingCode: "+962",
        countryName: "Jordan",
    },
    {
        countryCode: "JP",
        countryFlagSrc: "assets/country_flags/JP.png",
        countryDialingCode: "+81",
        countryName: "Japan",
    },
    {
        countryCode: "KE",
        countryFlagSrc: "assets/country_flags/KE.png",
        countryDialingCode: "+254",
        countryName: "Kenya",
    },
    {
        countryCode: "KG",
        countryFlagSrc: "assets/country_flags/KG.png",
        countryDialingCode: "+996",
        countryName: "Kyrgyzstan",
    },
    {
        countryCode: "KH",
        countryFlagSrc: "assets/country_flags/KH.png",
        countryDialingCode: "+855",
        countryName: "Cambodia",
    },
    {
        countryCode: "KM",
        countryFlagSrc: "assets/country_flags/KM.png",
        countryDialingCode: "+269",
        countryName: "Comoros",
    },
    {
        countryCode: "KN",
        countryFlagSrc: "assets/country_flags/KN.png",
        countryDialingCode: "+1 869",
        countryName: "Saint Kitts and Nevis",
    },
    {
        countryCode: "KR",
        countryFlagSrc: "assets/country_flags/KR.png",
        countryDialingCode: "+82",
        countryName: "Korea (South)",
    },
    {
        countryCode: "KW",
        countryFlagSrc: "assets/country_flags/KW.png",
        countryDialingCode: "+965",
        countryName: "Kuwait",
    },
    {
        countryCode: "KY",
        countryFlagSrc: "assets/country_flags/KY.png",
        countryDialingCode: "+1 345",
        countryName: "Cayman Islands",
    },
    {
        countryCode: "KZ",
        countryFlagSrc: "assets/country_flags/KZ.png",
        countryDialingCode: "+7",
        countryName: "Kazakhstan",
    },
    {
        countryCode: "LA",
        countryFlagSrc: "assets/country_flags/LA.png",
        countryDialingCode: "+856",
        countryName: "Lao PDR",
    },
    {
        countryCode: "LB",
        countryFlagSrc: "assets/country_flags/LB.png",
        countryDialingCode: "+961",
        countryName: "Lebanon",
    },
    {
        countryCode: "LC",
        countryFlagSrc: "assets/country_flags/LC.png",
        countryDialingCode: "+1 758",
        countryName: "Saint Lucia",
    },
    {
        countryCode: "LI",
        countryFlagSrc: "assets/country_flags/LI.png",
        countryDialingCode: "+423",
        countryName: "Liechtenstein",
    },
    {
        countryCode: "LK",
        countryFlagSrc: "assets/country_flags/LK.png",
        countryDialingCode: "+94",
        countryName: "Sri Lanka",
    },
    {
        countryCode: "LS",
        countryFlagSrc: "assets/country_flags/LS.png",
        countryDialingCode: "+266",
        countryName: "Lesotho",
    },
    {
        countryCode: "LT",
        countryFlagSrc: "assets/country_flags/LT.png",
        countryDialingCode: "+370",
        countryName: "Lithuania",
    },
    {
        countryCode: "LU",
        countryFlagSrc: "assets/country_flags/LU.png",
        countryDialingCode: "+352",
        countryName: "Luxembourg",
    },
    {
        countryCode: "LV",
        countryFlagSrc: "assets/country_flags/LV.png",
        countryDialingCode: "+371",
        countryName: "Latvia",
    },
    {
        countryCode: "LY",
        countryFlagSrc: "assets/country_flags/LY.png",
        countryDialingCode: "+218",
        countryName: "Libya",
    },
    {
        countryCode: "MA",
        countryFlagSrc: "assets/country_flags/MA.png",
        countryDialingCode: "+212",
        countryName: "Morocco",
    },
    {
        countryCode: "MD",
        countryFlagSrc: "assets/country_flags/MD.png",
        countryDialingCode: "+373",
        countryName: "Moldova",
    },
    {
        countryCode: "ME",
        countryFlagSrc: "assets/country_flags/ME.png",
        countryDialingCode: "+382",
        countryName: "Montenegro",
    },
    {
        countryCode: "MF",
        countryFlagSrc: "assets/country_flags/MF.png",
        countryDialingCode: "+590",
        countryName: "Saint-Martin (French part)",
    },
    {
        countryCode: "MG",
        countryFlagSrc: "assets/country_flags/MG.png",
        countryDialingCode: "+261",
        countryName: "Madagascar",
    },
    {
        countryCode: "MK",
        countryFlagSrc: "assets/country_flags/MK.png",
        countryDialingCode: "+389",
        countryName: "Macedonia, Republic of",
    },
    {
        countryCode: "MM",
        countryFlagSrc: "assets/country_flags/MM.png",
        countryDialingCode: "+95",
        countryName: "Myanmar",
    },
    {
        countryCode: "MN",
        countryFlagSrc: "assets/country_flags/MN.png",
        countryDialingCode: "+976",
        countryName: "Mongolia",
    },
    {
        countryCode: "MO",
        countryFlagSrc: "assets/country_flags/MO.png",
        countryDialingCode: "+853",
        countryName: "Macao, SAR China",
    },
    {
        countryCode: "MS",
        countryFlagSrc: "assets/country_flags/MS.png",
        countryDialingCode: "+1 664",
        countryName: "Montserrat",
    },
    {
        countryCode: "MT",
        countryFlagSrc: "assets/country_flags/MT.png",
        countryDialingCode: "+356",
        countryName: "Malta",
    },
    {
        countryCode: "MU",
        countryFlagSrc: "assets/country_flags/MU.png",
        countryDialingCode: "+230",
        countryName: "Mauritius",
    },
    {
        countryCode: "MW",
        countryFlagSrc: "assets/country_flags/MW.png",
        countryDialingCode: "+265",
        countryName: "Malawi",
    },
    {
        countryCode: "MX",
        countryFlagSrc: "assets/country_flags/MX.png",
        countryDialingCode: "+52",
        countryName: "Mexico",
    },
    {
        countryCode: "MY",
        countryFlagSrc: "assets/country_flags/MY.png",
        countryDialingCode: "+60",
        countryName: "Malaysia",
    },
    {
        countryCode: "MZ",
        countryFlagSrc: "assets/country_flags/MZ.png",
        countryDialingCode: "+258",
        countryName: "Mozambique",
    },
    {
        countryCode: "NA",
        countryFlagSrc: "assets/country_flags/NA.png",
        countryDialingCode: "+264",
        countryName: "Namibia",
    },
    {
        countryCode: "NC",
        countryFlagSrc: "assets/country_flags/NC.png",
        countryDialingCode: "+687",
        countryName: "New Caledonia",
    },
    {
        countryCode: "NE",
        countryFlagSrc: "assets/country_flags/NE.png",
        countryDialingCode: "+227",
        countryName: "Niger",
    },
    {
        countryCode: "NF",
        countryFlagSrc: "assets/country_flags/NF.png",
        countryDialingCode: "+672",
        countryName: "Norfolk Island",
    },
    {
        countryCode: "NG",
        countryFlagSrc: "assets/country_flags/NG.png",
        countryDialingCode: "+234",
        countryName: "Nigeria",
    },
    {
        countryCode: "NI",
        countryFlagSrc: "assets/country_flags/NI.png",
        countryDialingCode: "+505",
        countryName: "Nicaragua",
    },
    {
        countryCode: "NL",
        countryFlagSrc: "assets/country_flags/NL.png",
        countryDialingCode: "+31",
        countryName: "Netherlands",
    },
    {
        countryCode: "NO",
        countryFlagSrc: "assets/country_flags/NO.png",
        countryDialingCode: "+47",
        countryName: "Norway",
    },
    {
        countryCode: "NP",
        countryFlagSrc: "assets/country_flags/NP.png",
        countryDialingCode: "+977",
        countryName: "Nepal",
    },
    {
        countryCode: "NZ",
        countryFlagSrc: "assets/country_flags/NZ.png",
        countryDialingCode: "+64",
        countryName: "New Zealand",
    },
    {
        countryCode: "OM",
        countryFlagSrc: "assets/country_flags/OM.png",
        countryDialingCode: "+968",
        countryName: "Oman",
    },
    {
        countryCode: "PA",
        countryFlagSrc: "assets/country_flags/PA.png",
        countryDialingCode: "+507",
        countryName: "Panama",
    },
    {
        countryCode: "PE",
        countryFlagSrc: "assets/country_flags/PE.png",
        countryDialingCode: "+51",
        countryName: "Peru",
    },
    {
        countryCode: "PG",
        countryFlagSrc: "assets/country_flags/PG.png",
        countryDialingCode: "+675",
        countryName: "Papua New Guinea",
    },
    {
        countryCode: "PH",
        countryFlagSrc: "assets/country_flags/PH.png",
        countryDialingCode: "+63",
        countryName: "Philippines",
    },
    {
        countryCode: "PK",
        countryFlagSrc: "assets/country_flags/PK.png",
        countryDialingCode: "+92",
        countryName: "Pakistan",
    },
    {
        countryCode: "PL",
        countryFlagSrc: "assets/country_flags/PL.png",
        countryDialingCode: "+48",
        countryName: "Poland",
    },
    {
        countryCode: "PM",
        countryFlagSrc: "assets/country_flags/PM.png",
        countryDialingCode: "+508",
        countryName: "Saint Pierre and Miquelon",
    },
    {
        countryCode: "PR",
        countryFlagSrc: "assets/country_flags/PR.png",
        countryDialingCode: "1 939",
        countryName: "Puerto Rico",
    },
    {
        countryCode: "PS",
        countryFlagSrc: "assets/country_flags/PS.png",
        countryDialingCode: "+970",
        countryName: "Palestinian Territory",
    },
    {
        countryCode: "PT",
        countryFlagSrc: "assets/country_flags/PT.png",
        countryDialingCode: "+351",
        countryName: "Portugal",
    },
    {
        countryCode: "PY",
        countryFlagSrc: "assets/country_flags/PY.png",
        countryDialingCode: "+595",
        countryName: "Paraguay",
    },
    {
        countryCode: "QA",
        countryFlagSrc: "assets/country_flags/QA.png",
        countryDialingCode: "+974",
        countryName: "Qatar",
    },
    {
        countryCode: "RE",
        countryFlagSrc: "assets/country_flags/RE.png",
        countryDialingCode: "+262",
        countryName: "Réunion",
    },
    {
        countryCode: "RO",
        countryFlagSrc: "assets/country_flags/RO.png",
        countryDialingCode: "+40",
        countryName: "Romania",
    },
    {
        countryCode: "RS",
        countryFlagSrc: "assets/country_flags/RS.png",
        countryDialingCode: "+381",
        countryName: "Serbia",
    },
    {
        countryCode: "RU",
        countryFlagSrc: "assets/country_flags/RU.png",
        countryDialingCode: "+7",
        countryName: "Russian Federation",
    },
    {
        countryCode: "RW",
        countryFlagSrc: "assets/country_flags/RW.png",
        countryDialingCode: "+250",
        countryName: "Rwanda",
    },
    {
        countryCode: "SA",
        countryFlagSrc: "assets/country_flags/SA.png",
        countryDialingCode: "+966",
        countryName: "Saudi Arabia",
    },
    {
        countryCode: "SC",
        countryFlagSrc: "assets/country_flags/SC.png",
        countryDialingCode: "+248",
        countryName: "Seychelles",
    },
    {
        countryCode: "SE",
        countryFlagSrc: "assets/country_flags/SE.png",
        countryDialingCode: "+46",
        countryName: "Sweden",
    },
    {
        countryCode: "SG",
        countryFlagSrc: "assets/country_flags/SG.png",
        countryDialingCode: "+65",
        countryName: "Singapore",
    },
    {
        countryCode: "SH",
        countryFlagSrc: "assets/country_flags/SH.png",
        countryDialingCode: "+290",
        countryName: "Saint Helena",
    },
    {
        countryCode: "SI",
        countryFlagSrc: "assets/country_flags/SI.png",
        countryDialingCode: "+386",
        countryName: "Slovenia",
    },
    {
        countryCode: "SK",
        countryFlagSrc: "assets/country_flags/SK.png",
        countryDialingCode: "+421",
        countryName: "Slovakia",
    },
    {
        countryCode: "SL",
        countryFlagSrc: "assets/country_flags/SL.png",
        countryDialingCode: "+232",
        countryName: "Sierra Leone",
    },
    {
        countryCode: "SN",
        countryFlagSrc: "assets/country_flags/SN.png",
        countryDialingCode: "+221",
        countryName: "Senegal",
    },
    {
        countryCode: "SR",
        countryFlagSrc: "assets/country_flags/SR.png",
        countryDialingCode: "+597",
        countryName: "Suriname",
    },
    {
        countryCode: "ST",
        countryFlagSrc: "assets/country_flags/ST.png",
        countryDialingCode: "+239",
        countryName: "Sao Tome and Principe",
    },
    {
        countryCode: "SV",
        countryFlagSrc: "assets/country_flags/SV.png",
        countryDialingCode: "+503",
        countryName: "El Salvador",
    },
    {
        countryCode: "SZ",
        countryFlagSrc: "assets/country_flags/SZ.png",
        countryDialingCode: "+268",
        countryName: "Swaziland",
    },
    {
        countryCode: "TC",
        countryFlagSrc: "assets/country_flags/TC.png",
        countryDialingCode: "+1 649",
        countryName: "Turks and Caicos Islands",
    },
    {
        countryCode: "TG",
        countryFlagSrc: "assets/country_flags/TG.png",
        countryDialingCode: "+228",
        countryName: "Togo",
    },
    {
        countryCode: "TH",
        countryFlagSrc: "assets/country_flags/TH.png",
        countryDialingCode: "+66",
        countryName: "Thailand",
    },
    {
        countryCode: "TL",
        countryFlagSrc: "assets/country_flags/TL.png",
        countryDialingCode: "+670",
        countryName: "Timor-Leste",
    },
    {
        countryCode: "TM",
        countryFlagSrc: "assets/country_flags/TM.png",
        countryDialingCode: "+993",
        countryName: "Turkmenistan",
    },
    {
        countryCode: "TO",
        countryFlagSrc: "assets/country_flags/TO.png",
        countryDialingCode: "+676",
        countryName: "Tonga",
    },
    {
        countryCode: "TR",
        countryFlagSrc: "assets/country_flags/TR.png",
        countryDialingCode: "+90",
        countryName: "Turkey",
    },
    {
        countryCode: "TT",
        countryFlagSrc: "assets/country_flags/TT.png",
        countryDialingCode: "+1 868",
        countryName: "Trinidad and Tobago",
    },
    {
        countryCode: "TW",
        countryFlagSrc: "assets/country_flags/TW.png",
        countryDialingCode: "+886",
        countryName: "Taiwan, Republic of China",
    },
    {
        countryCode: "TZ",
        countryFlagSrc: "assets/country_flags/TZ.png",
        countryDialingCode: "+255",
        countryName: "Tanzania, United Republic of",
    },
    {
        countryCode: "UA",
        countryFlagSrc: "assets/country_flags/UA.png",
        countryDialingCode: "+380",
        countryName: "Ukraine",
    },
    {
        countryCode: "UG",
        countryFlagSrc: "assets/country_flags/UG.png",
        countryDialingCode: "+256",
        countryName: "Uganda",
    },
    {
        countryCode: "US",
        countryFlagSrc: "assets/country_flags/US.png",
        countryDialingCode: "+1",
        countryName: "United States of America",
    },
    {
        countryCode: "UY",
        countryFlagSrc: "assets/country_flags/UY.png",
        countryDialingCode: "+598",
        countryName: "Uruguay",
    },
    {
        countryCode: "UZ",
        countryFlagSrc: "assets/country_flags/UZ.png",
        countryDialingCode: "+998",
        countryName: "Uzbekistan",
    },
    {
        countryCode: "VC",
        countryFlagSrc: "assets/country_flags/VC.png",
        countryDialingCode: "+1 784",
        countryName: "Saint Vincent and Grenadines",
    },
    {
        countryCode: "VE",
        countryFlagSrc: "assets/country_flags/VE.png",
        countryDialingCode: "+58",
        countryName: "Venezuela (Bolivarian Republic)",
    },
    {
        countryCode: "VG",
        countryFlagSrc: "assets/country_flags/VG.png",
        countryDialingCode: "+1 284",
        countryName: "British Virgin Islands",
    },
    {
        countryCode: "VI",
        countryFlagSrc: "assets/country_flags/VI.png",
        countryDialingCode: "+1 340",
        countryName: "Virgin Islands, US",
    },
    {
        countryCode: "VN",
        countryFlagSrc: "assets/country_flags/VN.png",
        countryDialingCode: "+84",
        countryName: "Viet Nam",
    },
    {
        countryCode: "WS",
        countryFlagSrc: "assets/country_flags/WS.png",
        countryDialingCode: "+685",
        countryName: "Samoa",
    },
    {
        countryCode: "YE",
        countryFlagSrc: "assets/country_flags/YE.png",
        countryDialingCode: "+967",
        countryName: "Yemen",
    },
    {
        countryCode: "YT",
        countryFlagSrc: "assets/country_flags/YT.png",
        countryDialingCode: "+262",
        countryName: "Mayotte",
    },
    {
        countryCode: "ZA",
        countryFlagSrc: "assets/country_flags/ZA.png",
        countryDialingCode: "+27",
        countryName: "South Africa",
    },
    {
        countryCode: "ZM",
        countryFlagSrc: "assets/country_flags/ZM.png",
        countryDialingCode: "+260",
        countryName: "Zambia",
    },
    {
        countryCode: "ZW",
        countryFlagSrc: "assets/country_flags/ZW.png",
        countryDialingCode: "+263",
        countryName: "Zimbabwe",
    },
];
