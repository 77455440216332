import * as React from "react";
import Header from "../components/Header";

export const VanshVeli = () => {
    return (
        <div className={"w-[100vw] h-[100vh] bg-white flex items-center justify-center"}>
            <Header/>
            <h1 className={"text-[5vw] font-SOHNE_MONO_BOLD"}>Vansh Veli</h1>
        </div>
    )
}