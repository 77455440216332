import {Fragment, useState} from "react";
import {Popover, Transition} from "@headlessui/react";
import {ChevronDownIcon, PlayCircleIcon,} from "@heroicons/react/20/solid";
import {
    ArrowPathIcon,
    CalendarDaysIcon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    TvIcon
} from "@heroicons/react/24/outline";
import {ReaderIcon} from "@radix-ui/react-icons";
import {t} from "i18next";

export default function FlyoutMenu() {
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);

    return (
        <Popover className="relative">
            {
                ({}) => {
                    // To prevent the body from scrolling when the popover is open
                    document.body.classList.toggle('overflow-y-hidden', open);

                    return (
                        <>
                            <button
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                                className="py-[1vh] inline-flex items-center gap-x-1 text-md font-SOHNE_MEDIUM leading-6 text-gray-900 outline-none"
                            >
                                <span>{t("HEADER.FLYOUT_MENU.TITLE")}</span>
                                <ChevronDownIcon
                                    className={`h-[1.5vw] w-[1.5vw] ${hover || open ? 'rotate-180' : 'rotate-0'} transition-all duration-150 ease-in-out`}
                                    aria-hidden="true"
                                />
                            </button>

                            <Transition
                                show={open || hover}
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel
                                    onMouseEnter={() => setOpen(true)}
                                    onMouseLeave={() => setOpen(false)}
                                    className="absolute left-1/2 z-10 mt-[1vh] flex w-screen max-w-max -translate-x-1/2 rounded-3xl"
                                >
                                    <div
                                        className="bg-white w-screen max-w-sm flex-auto overflow-hidden rounded-3xl text-sm leading-6 shadow-lg ring-2 ring-orange-300/5"
                                    >
                                        <div className="h-[40vh] no-scrollbar overflow-y-auto p-[0.5vw]">
                                            <div
                                                key="Gallery"
                                                className="group relative flex gap-x-6 p-4 hover:bg-gray-50 rounded-2xl"
                                            >
                                                <div
                                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center bg-gray-50 group-hover:bg-orange-400 rounded-xl">
                                                    <ChartPieIcon
                                                        className="h-6 w-6 text-gray-600 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="font-SOHNE_MEDIUM text-gray-900"
                                                    >
                                                        {t("HEADER.FLYOUT_MENU.GALLERY.TITLE")}
                                                        <span className="absolute inset-0"/>
                                                    </a>
                                                    <p className="mt-1 text-gray-600 font-SOHNE_REGULAR">
                                                        {t("HEADER.FLYOUT_MENU.GALLERY.DESCRIPTION")}
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                key="Dharmic-Vihi"
                                                className="group relative flex gap-x-6 p-4 hover:bg-gray-50 rounded-2xl"
                                            >
                                                <div
                                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center bg-gray-50 group-hover:bg-orange-400 rounded-xl">
                                                    <ArrowPathIcon
                                                        className="h-6 w-6 text-gray-600 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="font-SOHNE_MEDIUM text-gray-900"
                                                    >
                                                        {t("HEADER.FLYOUT_MENU.DHARMIC_VIDHI.TITLE")}
                                                        <span className="absolute inset-0"/>
                                                    </a>
                                                    <p className="mt-1 text-gray-600 font-SOHNE_REGULAR">
                                                        {t("HEADER.FLYOUT_MENU.DHARMIC_VIDHI.DESCRIPTION")}
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                key="Dev-Darshan"
                                                className="group relative flex gap-x-6 p-4 hover:bg-gray-50 rounded-2xl"
                                            >
                                                <div
                                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center bg-gray-50 group-hover:bg-orange-400 rounded-xl">
                                                    <TvIcon
                                                        className="h-6 w-6 text-gray-600 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="font-SOHNE_MEDIUM text-gray-900"
                                                    >
                                                        {t("HEADER.FLYOUT_MENU.DEV_DARSHAN.TITLE")}
                                                        <span className="absolute inset-0"/>
                                                    </a>
                                                    <p className="mt-1 text-gray-600 font-SOHNE_REGULAR">
                                                        {t("HEADER.FLYOUT_MENU.DEV_DARSHAN.DESCRIPTION")}
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                key="Events"
                                                className="group relative flex gap-x-6 p-4 hover:bg-gray-50 rounded-2xl"
                                            >
                                                <div
                                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center bg-gray-50 group-hover:bg-orange-400 rounded-xl">
                                                    <CalendarDaysIcon
                                                        className="h-6 w-6 text-gray-600 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="font-SOHNE_MEDIUM text-gray-900"
                                                    >
                                                        {t("HEADER.FLYOUT_MENU.EVENTS.TITLE")}
                                                        <span className="absolute inset-0"/>
                                                    </a>
                                                    <p className="mt-1 text-gray-600 font-SOHNE_REGULAR">
                                                        {t("HEADER.FLYOUT_MENU.EVENTS.DESCRIPTION")}
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                key="GenAI-Info-Search"
                                                className="group relative flex gap-x-6 p-4 hover:bg-gray-50 rounded-2xl"
                                            >
                                                <div
                                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center bg-gray-50 group-hover:bg-orange-400 rounded-xl">
                                                    <CursorArrowRaysIcon
                                                        className="h-6 w-6 text-gray-600 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="font-SOHNE_MEDIUM text-gray-900"
                                                    >
                                                        {t("HEADER.FLYOUT_MENU.GEN_AI.TITLE")}
                                                        <span className="absolute inset-0"/>
                                                    </a>
                                                </div>
                                            </div>

                                            <div
                                                key="Joglekar-Kulbhushan"
                                                className="group relative flex gap-x-6 p-4 hover:bg-gray-50 rounded-2xl"
                                            >
                                                <div
                                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center bg-gray-50 group-hover:bg-orange-400 rounded-xl">
                                                    <FingerPrintIcon
                                                        className="h-6 w-6 text-gray-600 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="font-SOHNE_MEDIUM text-gray-900"
                                                    >
                                                        {t("HEADER.FLYOUT_MENU.JOGLEKAR_KULBHUSHAN.TITLE")}
                                                        <span className="absolute inset-0"/>
                                                    </a>
                                                </div>
                                            </div>

                                            <div
                                                key="Joglekar-Yellow-Page"
                                                className="group relative flex gap-x-6 p-4 hover:bg-gray-50 rounded-2xl"
                                            >
                                                <div
                                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center bg-gray-50 group-hover:bg-orange-400 rounded-xl">
                                                    <SquaresPlusIcon
                                                        className="h-6 w-6 text-gray-600 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="font-SOHNE_MEDIUM text-gray-900"
                                                    >
                                                        {t("HEADER.FLYOUT_MENU.JOGLEKAR_YELLOW_PAGE.TITLE")}
                                                        <span className="absolute inset-0"/>
                                                    </a>
                                                    <p className="mt-1 text-gray-600 font-SOHNE_REGULAR">
                                                        {t("HEADER.FLYOUT_MENU.JOGLEKAR_YELLOW_PAGE.DESCRIPTION")}
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                key="Joglekar-Anurup"
                                                className="group relative flex gap-x-6 p-4 hover:bg-gray-50 rounded-2xl"
                                            >
                                                <div
                                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center bg-gray-50 group-hover:bg-orange-400 rounded-xl">
                                                    <ArrowPathIcon
                                                        className="h-6 w-6 text-gray-600 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="font-SOHNE_MEDIUM text-gray-900"
                                                    >
                                                        {t("HEADER.FLYOUT_MENU.JOGLEKAR_ANURUP.TITLE")}
                                                        <span className="absolute inset-0"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                            <a
                                                key="Watch demo"
                                                href="#"
                                                className="flex items-center justify-center gap-x-2.5 p-3 font-SOHNE_MEDIUM text-gray-900 hover:bg-gray-100"
                                            >
                                                <PlayCircleIcon
                                                    className="h-5 w-5 flex-none text-gray-400"
                                                    aria-hidden="true"
                                                />
                                                {t("HEADER.FLYOUT_MENU.ACTION_BUTTON_1")}
                                            </a>
                                            <a
                                                key="Read-Instruction"
                                                href="#"
                                                className="flex items-center justify-center gap-x-2.5 p-3 font-SOHNE_MEDIUM text-gray-900 hover:bg-gray-100"
                                            >
                                                <ReaderIcon
                                                    className="h-5 w-5 flex-none text-gray-400"
                                                    aria-hidden="true"
                                                />
                                                {t("HEADER.FLYOUT_MENU.ACTION_BUTTON_2")}
                                            </a>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )
                }
            }
        </Popover>
    )
}
