import React, {useContext, useEffect} from 'react';
import {Handle, Position, useReactFlow} from 'reactflow';
import {AccountContext} from '../../context/AccountProvider';
import {NodeContext} from '../../context/NodeProvider';
import {t} from "i18next";

const MemberNode = ({data, isConnectable, id}) => {
    const instance = useReactFlow();

    const {account} = useContext(AccountContext);
    const {selectedNode, setRelativeNode, relativeNode} = useContext(NodeContext);

    function formatDate(dateString) {
        // Parse the ISO date string into a Date object
        const date = new Date(dateString);

        // Get the day of the month
        const day = date.getUTCDate();

        // Add the appropriate ordinal suffix to the day
        const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? 'st' :
            day % 10 === 2 && day !== 12 ? 'nd' :
                day % 10 === 3 && day !== 13 ? 'rd' : 'th');

        // Get the month as a short string
        const month = date.toLocaleString('en-US', {month: 'short'});

        // Get the year
        const year = date.getUTCFullYear();

        // Format the final output
        return `${dayWithSuffix} ${month}, ${year}`;
    }

    // const onDropdownChange = (event) => {
    //     const selectedValue = event.target.value;
    //     if (selectedValue === "") return;
    //     findRelative(id, selectedValue).then((response) => {
    //         console.log("Response from getFamilyTreeById", response);
    //         const relativeIds = response[0].members;
    //         if (relativeIds) {
    //             setRelativeNode(relativeIds);
    //         } else {
    //             setRelativeNode([]);
    //         }
    //     })
    // };

    useEffect(() => {
        console.log("Node Id: ", id);
        console.log("Node Data: ", data);
    }, []);

    return (
        <>
            <div
                className={`group rounded-lg shadow-lg px-[1vh] py-[1vh] flex items-center justify-between flex-row ${
                    selectedNode?._id === id ? "border-4 border-yellow-400" : "border-4 border-cyan-100"
                }`}
                style={{backgroundColor: data.gender === "Male" ? "#0075EA" : "#A020F0",}}
            >
                {
                    account.nodeId === id &&
                    <img src='assets/sideArrow.png'
                         alt="member image"
                         className="absolute left-4 rotate-90 overflow h-5 w-5 -top-8"
                    />
                }
                {
                    relativeNode.includes(id) &&
                    <img src='assets/down-arrow.png'
                         alt="member image"
                         className="absolute right-2 animate-bounce overflow h-10 w-10 -top-20"
                    />
                }

                {
                    data.imageUrl.length <= 2
                        ? (
                            <div
                                className={`z-10 w-[7vh] h-[7vh] mr-[1vw] overflow-hidden flex items-center justify-center rounded-lg border-2 border-white ${data.imageUrlBg}`}
                            >
                                <p className={"text-white font-SOHNE_MONO_BOLD"}>
                                    {data.imageUrl.toUpperCase()}
                                </p>
                            </div>
                        )
                        : (
                            <div
                                className="z-10 w-[7vh] h-[7vh] mr-[1vw] overflow-hidden flex items-center justify-center rounded-lg bg-slate-500 border-2 border-white">
                                <img
                                    src={!(data.imageUrl === '') ? data.imageUrl : 'assets/default-pfp.png'}
                                    referrerPolicy={"no-referrer"}
                                    alt="Member Profile Picture"
                                />
                            </div>
                        )
                }

                <div className="flex-col items-center justify-between mr-[0.5vw]">
                    <p className="text-xs font-medium text-white leading-relaxed">
                        {data.firstName + ' ' + data.middleName}
                    </p>
                    <p className="text-xs font-medium text-white leading-relaxed">
                        {data.firstNameDevanagariScript + ' ' + data.middleNameDevanagariScript}
                    </p>
                    <p className="text-xs text-white ">
                        {t("SCREEN.MEMBER_NODE.DOB_PREFIX")}&nbsp;
                        <span>{formatDate(data.dateOfBirth)}</span>
                    </p>
                </div>

                {/*<NodeToolbar*/}
                {/*    position={Position.Bottom}*/}
                {/*    className="rounded-lg flex flex-row items-center justify-between"*/}
                {/*>*/}
                {/*    <div className="flex content-center">*/}
                {/*        <button*/}
                {/*            className="flex flex-row items-center justify-between mr-[0.5vw] px-[0.75vw] py-[0.75vh] bg-green-400 rounded-lg hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300"*/}
                {/*            onClick={() => data.setShowEditNodeForm(true)}*/}
                {/*        >*/}
                {/*            <img*/}
                {/*                src="assets/edit_icon_dark.png"*/}
                {/*                alt="Edit"*/}
                {/*                className="w-[1vw] h-[1vw]"*/}
                {/*            />*/}
                {/*            <p className={"ml-1 text-[1vw] font-SOHNE_MEDIUM"}>*/}
                {/*                {t("SCREEN.MEMBER_NODE.EDIT")}*/}
                {/*            </p>*/}
                {/*        </button>*/}

                {/*        {account.nodeId !== id &&*/}
                {/*            <button*/}
                {/*                onClick={() => {*/}
                {/*                    data.deleteNode([{id, data}]);*/}
                {/*                    instance.deleteElements({nodes: [{id: id}]});*/}
                {/*                }}*/}
                {/*                className="flex flex-row items-center justify-between ml-[0.5vw] px-[0.75vw] py-[0.75vh] focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"*/}
                {/*            >*/}
                {/*                <img*/}
                {/*                    src="assets/delete_icon_dark.png"*/}
                {/*                    alt="Delete"*/}
                {/*                    className="w-[1vw] h-[1vw]"*/}
                {/*                />*/}
                {/*                <p className={"ml-1 text-[1vw] font-SOHNE_MEDIUM"}>*/}
                {/*                    {t("SCREEN.MEMBER_NODE.DELETE")}*/}
                {/*                </p>*/}
                {/*            </button>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</NodeToolbar>*/}

                {/*<div*/}
                {/*    className="absolute invisible transition transform -translate-y-20 group-hover:ease-in-out duration-400 mb-8 text-wrap bg-white px-3 pb-2 border rounded-lg group-hover:visible group-hover:translate-y-15"*/}
                {/*>*/}
                {/*    <b>*/}
                {/*        <p>{data.firstName + ' ' + data.lastName}</p>*/}
                {/*    </b>*/}
                {/*    <div className="font-medium text-xs text-gray-900">*/}
                {/*        <div>DOB:&nbsp;*/}
                {/*            <span className=" text-gray-400">*/}
                {/*                {data.dateOfBirth.substring(0, 10)}*/}
                {/*            </span>*/}
                {/*        </div>*/}

                {/*        {data.dateOfDeath &&*/}
                {/*            <div>DOD:&nbsp;*/}
                {/*                <span className=" text-gray-400">*/}
                {/*                    {data.dateOfDeath.substring(0, 10)}*/}
                {/*                </span>*/}
                {/*            </div>*/}
                {/*        }*/}

                {/*        <div>Gender:&nbsp;*/}
                {/*            <span className=" text-gray-400">*/}
                {/*                {data.gender}*/}
                {/*            </span>*/}
                {/*        </div>*/}

                {/*        /!*{data.biography &&*!/*/}
                {/*        /!*    <div>Bio:&nbsp;*!/*/}
                {/*        /!*        <span className="text-gray-400">*!/*/}
                {/*        /!*            {data.biography}*!/*/}
                {/*        /!*        </span>*!/*/}
                {/*        /!*    </div>*!/*/}
                {/*        /!*}*!/*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Handle
                    type="target"
                    style={{background: '#90EE90', padding: 5}}
                    id="a"
                    position={Position.Top}
                    isConnectable={isConnectable}
                />
                <Handle
                    type="source"
                    style={{background: '#90EE90', padding: 5}}
                    id="a"
                    position={Position.Top}
                    isConnectable={isConnectable}
                />
                <Handle
                    type="target"
                    position={Position.Left}
                    id="b"
                    style={{background: '#90EE90', padding: 5}}
                    isConnectable={isConnectable}
                />
                <Handle
                    type="source"
                    position={Position.Left}
                    id="b"
                    style={{background: '#90EE90', padding: 5}}
                    isConnectable={isConnectable}
                />
                <Handle
                    type="target"
                    position={Position.Right}
                    id="c"
                    style={{background: '#90EE90', padding: 5}}
                    isConnectable={isConnectable}
                />
                <Handle
                    type="source"
                    position={Position.Right}
                    id="c"
                    style={{background: '#90EE90', padding: 5}}
                    isConnectable={isConnectable}
                />
                <Handle
                    type="target"
                    position={Position.Bottom}
                    id="d"
                    style={{background: '#90EE90', padding: 5}}
                    isConnectable={isConnectable}
                />
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id="d"
                    style={{background: '#90EE90', padding: 5}}
                    isConnectable={isConnectable}
                />
            </div>
        </>
    );
};
export default MemberNode;