import axios from 'axios';

const URL = "";

export const addAccount = async (account) => {
    try {
        const response = await axios.post(`${URL}/account/signup`, account);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const addInviteAccount = async (account) => {
    try {
        const response = await axios.post(`${URL}/account/invite`, account);
        console.log("Add Invite Account Response: ", response);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getAccount = async (data) => {

    try {
        const response = await axios.get(`${URL}/account/login`, {params: data});
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error) {
        console.error(error);
    }
}

// export const getAccount = async (googleId) => {
//     try {
//         const response = await axios.get(`${URL}/account/get/:${googleId}`);
//         return response.data;
//     } catch (error) {
//         console.error(error);
//     }
// }

export const updateAccountById = async (id, updatedAccountDetails) => {
    try {
        const response = await axios.put(`${URL}/account/update/${id}`, updatedAccountDetails);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// export const deleteAccount = async (email) => {
//     try {
//         const response = await axios.delete(`${URL}/account/delete/${email}`);
//         return response.data;
//     } catch (error) {
//         console.error(error);
//     }
// }

export const deleteAccount = async (account) => {
    try {
        const response = await axios.delete(`${URL}/account/delete/${account.email}`, account);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const addMember = async (member) => {
    try {
        const response = await axios.post(`${URL}/member/add`, member);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const uploadFileToAzure = async (file) => {
    try {
        const formData = new FormData();
        formData.append("user-pfp", file);
        const response = await axios.post(`${URL}/file/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log("(accountApi.js) File Upload Response: ", response);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
