import React from 'react';

import {ReactFlowProvider} from 'reactflow';
import Screen from '../components/Screen';
import 'reactflow/dist/style.css';
import Header from '../components/Header';
import {AccountContext} from '../context/AccountProvider';
import CreateProfile from "../components/CreateProfile";

export default function MainPage() {
    const {account, loading} = React.useContext(AccountContext);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <ReactFlowProvider>
            <Header/>
            {account.nodeId ? <Screen/> : <CreateProfile/>}
        </ReactFlowProvider>
    );
}
