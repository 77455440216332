import React, {useCallback, useState} from 'react';
import {AccountContext} from '../../context/AccountProvider';
import {translateTextToDevnagri} from '../../service/translateApi';
import {t} from "i18next";
import {Modal} from "../../components/Modal";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {Transition} from "@headlessui/react";
import {addInviteAccount, addMember} from "../../service/accountApi";

export const AddNodeForm = (props) => {
    const {
        showAddNodeForm,
        setShowAddNodeForm,
        restoreFlowFromDB
    } = props;
    const pfpBgColors = ["bg-[#0F1F2E]", "bg-[#FFEF5C]", "bg-[#FF8C42]", "bg-[#FF3C38]", "bg-[#A23E48]"];
    const {account} = React.useContext(AccountContext);
    const [error, setError] = useState({});

    const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState(false);

    const [isAddMemberButtonDisabled, setIsAddMemberButtonDisabled] = useState(false);

    const [member, setMember] = useState({
        deceased: false,

        firstName: '',
        middleName: '',

        firstNameDevanagariScript: '',
        middleNameDevanagariScript: '',

        dateOfBirth: '',
        gender: 'Male',
        dateOfDeath: '',

        imageUrl: '',
        imageUrlBg: pfpBgColors[Math.floor(Math.random() * pfpBgColors.length)],
    });

    const [inviteUser, setInviteUser] = useState(false);
    const [invitedUserEmail, setInvitedUserEmail] = useState('');
    const [needAssistance, setNeedAssistance] = useState(false);

    const translateToDevnagri = (e) => {
        if (e.target.name === 'firstName' || e.target.name === 'middleName') {
            if (e.target.value) {
                translateTextToDevnagri(e.target.value).then((res) => {
                    console.log("(CreateProfile.jsx) Translation Response: ", res);
                    setMember({
                        ...member,
                        [e.target.name + 'DevanagariScript']: res.result
                    });
                    setError({
                        ...error,
                        [e.target.name + 'DevanagariScript']: ""
                    });
                });
            }
        }
    };

    const handleGenderSelect = (gender) => {
        setIsGenderDropdownOpen(false);
        setMember({
            ...member,
            gender: gender
        });
    }

    const validateEmail = (email) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    };

    const validateEmailId = (e) => {
        if (!validateEmail(e.target.value)) {
            setError({
                ...error,
                [e.target.name]: t("ADD_NODE_FORM.SEND_REQUEST.EMAIL.INVALID_ERROR")
            });
        } else {
            setError({
                ...error,
                [e.target.name]: ""
            })
        }
    }

    const handleFormData = (e) => {
        console.log(e.target.name + ': ' + e.target.value);

        setMember({
            ...member,
            [e.target.name]: e.target.value
        });

        setError({
            ...error,
            [e.target.name]: ""
        });
    }

    const handleCheckbox = useCallback((e) => {
        const {name, checked} = e.target;
        console.log("name:", name, " checked: ", checked);
        setMember((prev) => ({...prev, [name]: checked}));
    }, []);

    const validate = () => {
        let err = {};

        if (member.firstName === '') {
            err['firstName'] = t("ADD_NODE_FORM.NAME.ENGLISH.FIRST_NAME_ERROR");
        }

        if (member.middleName === '') {
            err['middleName'] = t("ADD_NODE_FORM.NAME.ENGLISH.MIDDLE_NAME_ERROR");
        }

        if (member.firstNameDevanagariScript === '') {
            err['firstName'] = t("ADD_NODE_FORM.NAME.DEVANAGRI.FIRST_NAME_ERROR");
        }

        if (member.middleNameDevanagariScript === '') {
            err['middleName'] = t("ADD_NODE_FORM.NAME.DEVANAGRI.MIDDLE_NAME_ERROR");
        }

        if (member.dateOfBirth === '') {
            err['dateOfBirth'] = t("ADD_NODE_FORM.DOB.ERROR");
        }

        if (member.deceased) {
            if (member.dateOfDeath === '') {
                err['dateOfDeath'] = t("ADD_NODE_FORM.DOD.ERROR");
            }
        }

        if (inviteUser && invitedUserEmail === '') {
            err['email'] = t("ADD_NODE_FORM.SEND_REQUEST.EMAIL.REQUIRED_ERROR");
        }

        return err;
    }

    const handleSubmit = async () => {
        setIsAddMemberButtonDisabled(true);
        const err = validate();

        if (Object.keys(err).length > 0) {
            setError(err);
            setIsAddMemberButtonDisabled(false);
            return;
        }

        member.imageUrl = member.firstName[0] + member.middleName[0];

        if (inviteUser) {
            const newAccountData = {
                creatorNodeId: account.nodeId,
                email: invitedUserEmail,
                firstName: member.firstName,
                middleName: member.middleName,
                firstNameDevanagariScript: member.firstNameDevanagariScript,
                middleNameDevanagariScript: member.middleNameDevanagariScript,
                gender: member.gender,
                dateOfBirth: member.dateOfBirth,
                familyTreeName: account.familyTreeName,
                familyTreeId: account.familyTreeId,
                imageUrl: member.imageUrl,
                imageUrlBg: member.imageUrlBg,
                relativeName: account.firstName + " " + account.middleName,
            };

            const addInviteAccountResponse = await addInviteAccount(newAccountData);

            console.log('(AddNodeForm) Add Invite Account Response: ', addInviteAccountResponse);

            if (addInviteAccountResponse.success) {
                await restoreFlowFromDB();
                setShowAddNodeForm(false);
                setIsAddMemberButtonDisabled(false);
                setMember({});
            }
        } else {
            const newNode = {
                type: 'Member',
                data: {
                    ...member,
                    creatorNodeId: account.nodeId
                },
                position: {
                    x: 500 + 40,
                    y: 500,
                },
            };

            // Add new member node
            const addMemberResponse = await addMember(newNode);

            console.log("(AddNodeForm) Add Member Response: ", addMemberResponse);

            if (addMemberResponse.success) {
                await restoreFlowFromDB();
                setShowAddNodeForm(false);
                setIsAddMemberButtonDisabled(false);
                setMember({});
            }
        }
    }

    return (
        <Modal isOpen={showAddNodeForm}>
            <div className="flex flex-col justify-between max-h-[80vh] rounded-xl px-[1.5vw] py-[2vh] bg-white">
                {/* Header */}
                <div className={"max-h-[10vh]"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        {/* Form Title */}
                        <h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
                            {t("ADD_NODE_FORM.TITLE")}
                        </h2>

                        <button
                            onClick={() => setShowAddNodeForm(false)}
                            type="button"
                            data-drawer-hide="drawer-form"
                            aria-controls="drawer-form"
                            className="flex items-center justify-center rounded-lg w-[2vw] h-[2vw]
                            bg-transparent hover:bg-gray-200
                            text-sm text-gray-400 hover:text-gray-900"
                        >
                            <img
                                className={"w-[75%] h-[75%]"}
                                src={"assets/close-icon.png"}
                                alt={"Close Icon"}
                            />
                        </button>
                    </div>

                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
                </div>

                <div className={"flex-1 no-scrollbar overflow-y-scroll max-h-[60vh] py-[1.5vh]"}>
                    {/* Name in English */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                                htmlFor="firstName"
                            >
                                {t("ADD_NODE_FORM.NAME.ENGLISH.LABEL")}
                            </label>
                            <div className="flex justify-between gap-4">
                                {/* First Name */}
                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="firstName"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='firstName'
                                        placeholder={t("ADD_NODE_FORM.NAME.PLACEHOLDER.FIRST_NAME")}
                                        value={member.firstName}
                                        onChange={handleFormData}
                                        onBlur={translateToDevnagri}
                                    />
                                    {
                                        error['firstName'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['firstName']}
                                        </p>
                                    }
                                </div>

                                {/* Father's Name */}
                                <div className="flex-1 flex flex-col">
                                    <input
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        id="middleName"
                                        name='middleName'
                                        placeholder={t("ADD_NODE_FORM.NAME.PLACEHOLDER.MIDDLE_NAME")}
                                        value={member.middleName}
                                        onChange={handleFormData}
                                        onBlur={translateToDevnagri}
                                    />
                                    {
                                        error['middleName'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['middleName']}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    {/* Name in Devnagari) */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                                htmlFor="firstNameDevanagariScript"
                            >
                                {t("ADD_NODE_FORM.NAME.DEVANAGRI.LABEL")}
                            </label>
                            <div className="flex justify-between gap-4">
                                {/* First Name */}
                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="firstNameDevanagariScript"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='firstNameDevanagariScript'
                                        placeholder={t("ADD_NODE_FORM.NAME.PLACEHOLDER.FIRST_NAME_DEVANAGRI")}
                                        value={member.firstNameDevanagariScript}
                                        onChange={handleFormData}
                                        onBlur={translateToDevnagri}
                                    />
                                    {
                                        error['firstNameDevanagariScript'] &&
                                        <span className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['firstNameDevanagariScript']}
                                        </span>
                                    }
                                </div>

                                {/* Father's Name */}
                                <div className="flex-1 flex flex-col">
                                    <input
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        id="middleNameDevanagariScript"
                                        name='middleNameDevanagariScript'
                                        placeholder={t("ADD_NODE_FORM.NAME.PLACEHOLDER.MIDDLE_NAME_DEVANAGRI")}
                                        value={member.middleNameDevanagariScript}
                                        onChange={handleFormData}
                                        onBlur={translateToDevnagri}
                                    />
                                    {
                                        error['middleNameDevanagariScript'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['middleNameDevanagariScript']}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    {/* Deceased */}
                    <fieldset>
                        <div className={"mb-[2vh] flex flex-col gap-2"}>
                            {/*<label*/}
                            {/*    className="cursor-pointer min-h-[5vh] max-h-[5vh] w-fit flex flex-row items-center gap-2*/}
                            {/*    rounded-md px-[1vw] py-[1vh] border-2 border-gray-300">*/}
                            <label className="w-fit flex flex-row items-center gap-2">
                                <input
                                    type="checkbox"
                                    name='deceased'
                                    checked={member.deceased}
                                    onChange={handleCheckbox}
                                />
                                <p className="font-SOHNE_REGULAR text-[1vw]">
                                    {t("ADD_NODE_FORM.DECEASED")}
                                </p>
                                <img
                                    className={"w-[1.5vw] aspect-auto"}
                                    src={"assets/deceased-icon.png"}
                                    alt={"Male"}
                                />
                            </label>
                        </div>
                    </fieldset>

                    {/* Gender | D.O.B | D.O.D */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-row justify-between gap-4">
                            {/* Date of Birth */}
                            <div className="flex flex-col gap-2">
                                <label
                                    className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
                                    htmlFor='dateOfBirth'
                                >
                                    {t("ADD_NODE_FORM.DOB.LABEL")}
                                </label>
                                <div className="relative w-full">
                                    <input
                                        type="date"
                                        name='dateOfBirth'
                                        required
                                        value={member.dateOfBirth}
                                        onChange={handleFormData}
                                        className={`font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                            flex-1 items-center justify-center rounded-md text-[1vw]
                                            ${member.deceased ? 'px-[0.75vw]' : 'px-[1vw]'}
                                            leading-none outline-none border-2 border-gray-300
                                            focus:border-[#5F0F40]`}
                                    />
                                    {
                                        error['dateOfBirth'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['dateOfBirth']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* Gender */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR" htmlFor="gender">
                                    {t("ADD_NODE_FORM.GENDER.LABEL")}
                                </label>

                                <div className="relative w-full flex flex-col">
                                    <button
                                        onBlur={() => setIsGenderDropdownOpen(false)}
                                        className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
                                        onClick={() => setIsGenderDropdownOpen(!isGenderDropdownOpen)}
                                    >
                                        <div className={"flex-1 flex flex-row items-center gap-2"}>
                                            <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                {
                                                    member.gender === "Male"
                                                        ? t("ADD_NODE_FORM.GENDER.MALE")
                                                        : t("ADD_NODE_FORM.GENDER.FEMALE")
                                                }
                                            </p>
                                            <img
                                                className={"w-[1.5vw] aspect-auto"}
                                                src={member.gender === "Male" ? "assets/male-gender-icon.png" : "assets/female-gender-icon.png"}
                                                alt={member.gender === "Male" ? "Male" : "Female"}
                                            />
                                        </div>
                                        <ChevronDownIcon
                                            className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isGenderDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
                                            aria-hidden="true"
                                            stroke="#292D32"
                                            strokeWidth={"0.1vh"}
                                        />
                                    </button>

                                    {/* Gender Dropdown */}
                                    <div className={"relative"}>
                                        <Transition show={isGenderDropdownOpen}>
                                            <div
                                                className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                origin-top-right rounded-md shadow-2xl 
                                                border border-gray-700 overflow-y-scroll
                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                data-[leave]:duration-75 data-[leave]:ease-in`}
                                            >
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center gap-2"
                                                    onClick={() => handleGenderSelect("Male")}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("ADD_NODE_FORM.GENDER.MALE")}
                                                    </p>
                                                    <img
                                                        className={"w-[1.5vw] aspect-auto"}
                                                        src={"assets/male-gender-icon.png"}
                                                        alt={"Male"}
                                                    />
                                                </button>
                                                <button
                                                    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center gap-2"
                                                    onClick={() => handleGenderSelect("Female")}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {t("ADD_NODE_FORM.GENDER.FEMALE")}
                                                    </p>
                                                    <img
                                                        className={"w-[1.5vw] aspect-auto"}
                                                        src={"assets/female-gender-icon.png"}
                                                        alt={"Female"}
                                                    />
                                                </button>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>

                            {/* Date of Death */}
                            {
                                member.deceased &&
                                <div className="flex flex-col gap-2">
                                    <label
                                        className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
                                        htmlFor='dateOfDeath'
                                    >
                                        {t("ADD_NODE_FORM.DOD.LABEL")}
                                    </label>
                                    <div className="relative w-full">
                                        <input
                                            type="date"
                                            name='dateOfDeath'
                                            required
                                            value={member.dateOfDeath}
                                            onChange={handleFormData}
                                            className="font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                            flex-1 items-center justify-center rounded-md px-[0.75vw] text-[1vw]
                                            leading-none outline-none border-2 border-gray-300
                                            focus:border-[#5F0F40]"
                                        />
                                        {
                                            error['dateOfDeath'] &&
                                            <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                                {error['dateOfDeath']}
                                            </p>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </fieldset>

                    {/* Send Request to Join */}
                    {
                        !member.deceased &&
                        <fieldset>
                            <div className={"flex flex-col gap-2"}>
                                {/*<label*/}
                                {/*    className="cursor-pointer min-h-[5vh] max-h-[5vh] w-fit flex flex-row items-center gap-2*/}
                                {/*    rounded-md px-[1vw] py-[1vh] border-2 border-gray-300">*/}
                                <label className="flex flex-row items-center gap-2">
                                    <input
                                        type="checkbox"
                                        name='isUser'
                                        checked={inviteUser}
                                        onChange={() => setInviteUser(!inviteUser)}
                                    />
                                    <p className="font-SOHNE_REGULAR text-[1vw]">
                                        {t("ADD_NODE_FORM.SEND_REQUEST.LABEL")}
                                    </p>
                                </label>
                            </div>
                        </fieldset>
                    }

                    {/* Email Input if Send Request option is checked */}
                    {inviteUser &&
                        <fieldset>
                            <div className="mt-[2vh] flex flex-col gap-2">
                                <label
                                    htmlFor="email"
                                    className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                                >
                                    {t("ADD_NODE_FORM.SEND_REQUEST.EMAIL.LABEL")}
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name='email'
                                    className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                    value={invitedUserEmail}
                                    onChange={(e) => setInvitedUserEmail(e.target.value)}
                                    onBlur={validateEmailId}
                                    required
                                />
                                {
                                    error['email'] &&
                                    <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                        {error['email']}
                                    </p>
                                }
                            </div>
                        </fieldset>
                    }
                </div>

                {/* Footer */}
                <div className={"max-h-[10vh]"}>
                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mb-[1.5vh]"}/>

                    <div className="flex items-center justify-between">
                        {/* Need Assistance */}
                        <fieldset>
                            <label className="flex flex-row items-center gap-2">
                                <input
                                    type="checkbox"
                                    name='needAssistance'
                                    checked={needAssistance}
                                    onChange={() => setNeedAssistance(!needAssistance)}
                                />
                                <p className="font-SOHNE_REGULAR text-[1vw]">
                                    {t("ADD_NODE_FORM.NEED_ASSISTANCE")}
                                </p>
                            </label>
                        </fieldset>

                        {/* Submit Button */}
                        <button
                            disabled={isAddMemberButtonDisabled}
                            onClick={handleSubmit}
                            className="inline-flex items-center arrow-button font-SOHNE_MEDIUM
                            bg-green-400 hover:bg-green-500 hover:ring-white hover:ring-2
                            disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full
                            pl-[1.25vw] pr-[0.75vw] py-[1vh] transition-all duration-300 ease-in-out"
                        >
                            {t("ADD_NODE_FORM.ADD_MEMBER")}
                            <svg
                                viewBox="0 0 6 6"
                                fill="none"
                                id={"arrow-icon"}
                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 1L5 3L3 5"
                                    id={"arrow-icon-tip"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                                <path
                                    d="M1 3L4 3"
                                    id={"arrow-icon-line"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}