import React, {createContext, useEffect, useState} from "react";
import {getAccount} from "../service/accountApi";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const AccountContext = createContext(null);

const AccountProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [cookie, setCookie] = useState(cookies.get('email'));
    const [account, setAccount] = useState({});

    // useEffect(() => {
    //     setCookie(cookies.get('email'));
    //     console.log("(AccountProvider) Cookie in useEffect: ", cookie);
    // }, []);q

    useEffect(() => {
        const fetchAccount = async () => {
            setLoading(true);
            if (cookie) {
                const data = await getAccount({email: cookie});
                console.log("(AccountProvider) Account Data: ", data);
                if (data) {
                    setAccount(data);
                }
            }
            setLoading(false);
        };
        fetchAccount();
    }, [cookie]);

    return (
        <AccountContext.Provider value={{account, setAccount, cookie, setCookie, loading}}>
            {children}
        </AccountContext.Provider>
    )
}
export default AccountProvider;