import React, {useContext, useState} from "react"
import {generateOtp, matchOtp} from "../service/userOtpApi"
import Cookies from "universal-cookie";
import {AccountContext} from "../context/AccountProvider";
import {t} from "i18next";

const EmailPhoneInput = ({isOpen, setIsOpen, setEmailWithNoAccount}) => {
    const [verifier, setVerifier] = useState("");
    const [userData, setUserData] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const [otp, setOtp] = useState("");
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const cookies = new Cookies();
    const {setAccount, setCookie} = useContext(AccountContext);

    const emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const phoneRegx = /^\d{10}$/;

    console.log("User data: ", userData);

    const sendOtpHandler = () => {
        if (!verifier) {
            setMessage(t("LOGIN_PAGE.EMAIL_PHONE_INPUT.ERROR.EMPTY_INPUT"));
        } else {
            const isEmailValid = emailRegx.test(verifier);
            const isPhoneNoValid = phoneRegx.test(verifier);
            
            let verificationType = null;

            if (isEmailValid) {
                verificationType = "email";
            } else if (isPhoneNoValid) {
                verificationType = "phone";
            } else {
                setMessage(t("LOGIN_PAGE.EMAIL_PHONE_INPUT.ERROR.INVALID_INPUT"));
            }

            if (verificationType) {
                setIsDisabled(true);
                generateOtp(verifier, verificationType)
                    .then((result) => {
                        setUserData(result.data);
                        setSuccess(true);
                        setMessage("");
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                        setSuccess(false);
                        if (error.message.includes("Network Error")) {
                            setMessage("There was some problem connecting to the server. Please try again in sometime!");
                        } else {
                            setMessage(error.message);
                        }
                    })
                    .finally(() => {
                        setIsDisabled(false);
                    });
            }
        }
    }

    const matchOtpHandler = () => {
        if (userData && userData._id && userData.otp) {
            matchOtp(userData._id, userData.otp).then((result) => {
                console.log("Match OTP Result in EmailPhoneInput Component: ", result);
                if (result.success) {
                    if (result.user) {
                        cookies.set("email", result.user.email, {path: "/"});
                        setAccount((prevState) => ({
                            ...prevState,
                            ...result.user,
                        }));
                        setCookie(cookies.get("email"));
                    } else {
                        setEmailWithNoAccount(verifier);
                        setIsOpen(true);
                    }
                }
            })
        }
    }

    return (
        <div>
            <label
                htmlFor="email"
                className="block text-sm font-SOHNE_MEDIUM leading-6 text-gray-900"
            >
                {t("LOGIN_PAGE.EMAIL_PHONE_INPUT.LABEL")}
            </label>
            <div className="flex gap-2 mt-[0.5vh]">
                <input
                    type="email"
                    name="email"
                    value={verifier}
                    onChange={(e) => setVerifier(e.target.value)}
                    id="email"
                    className="flex-1 font-SOHNE_LIGHT rounded-full py-[1.5vh] px-[1.25vw] text-gray-900 shadow-sm border-gray-300 border text-sm leading-tight outline-none focus:ring-4 focus:ring-offset-1 focus:ring-indigo-600 transition-all duration-300 ease-in-out"
                />
                <button
                    disabled={isDisabled}
                    onClick={sendOtpHandler}
                    className="font-SOHNE_MEDIUM inline-flex items-center arrow-button bg-[#D26A53] leading-tight hover:ring-yellow-600 hover:ring-offset-1 hover:ring-4 disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full pl-[1.25vw] pr-[0.75vw] py-[1.5vh] text-white transition-all duration-300 ease-in-out"
                >
                    {t("LOGIN_PAGE.EMAIL_PHONE_INPUT.SEND_OTP")}
                    <svg
                        viewBox="0 0 6 6"
                        fill="none"
                        id={"arrow-icon"}
                        className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3 1L5 3L3 5"
                            id={"arrow-icon-tip"}
                            stroke="white"
                            strokeLinecap="square"
                        />
                        <path
                            d="M1 3L4 3"
                            id={"arrow-icon-line"}
                            stroke="white"
                            strokeLinecap="square"
                        />
                    </svg>
                </button>
            </div>
            {success && (
                <div className="mt-[1vh]">
                    <label
                        htmlFor="email"
                        className="font-SOHNE_MEDIUM block text-sm font-medium leading-6 text-gray-900"
                    >
                        {t("LOGIN_PAGE.EMAIL_PHONE_INPUT.ENTER_OTP")}
                    </label>
                    <div className="flex gap-2 mt-[0.5vh]">
                        <input
                            type="text"
                            name="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            id="otp"
                            className="flex-1 font-SOHNE_LIGHT rounded-full py-[1vh] px-[1vw] text-gray-900 border-gray-300 border shadow-sm text-sm leading-tight outline-none focus:ring-4 focus:ring-offset-1 focus:ring-indigo-600 transition-all duration-300 ease-in-out"
                        />
                        <button
                            onClick={matchOtpHandler}
                            className="font-SOHNE_MEDIUM inline-flex items-center arrow-button bg-[#D26A53] leading-tight hover:ring-yellow-600 hover:ring-offset-1 hover:ring-4 disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full pl-[1.25vw] pr-[0.75vw] py-[1.5vh] text-white transition-all duration-300 ease-in-out"
                        >
                            {t("LOGIN_PAGE.EMAIL_PHONE_INPUT.VERIFY_OTP")}
                            <svg
                                viewBox="0 0 6 6"
                                fill="none"
                                id={"arrow-icon"}
                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 1L5 3L3 5"
                                    id={"arrow-icon-tip"}
                                    stroke="white"
                                    strokeLinecap="square"
                                />
                                <path
                                    d="M1 3L4 3"
                                    id={"arrow-icon-line"}
                                    stroke="white"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
            {message && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {message}
                </p>
            )}
        </div>
    )
}

export default EmailPhoneInput;
