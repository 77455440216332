import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "../src/locales/en.json";
import mr from "../src/locales/mr.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const resources = {
    en: {translation: en},
    mr: {translation: mr}
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en",
        lng: cookies.get("locale") || "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

// import {i18n} from "@lingui/core";
// import Cookies from "universal-cookie";
//
// const cookies = new Cookies()
// export const locales = {
//     en: "English",
//     hi: "Hindi",
//     mr: "Marathi"
// }
// export const defaultLocale = cookies.get("locale") || "en"
//
// // CRA mark all resources which are not JS as 'asset/resource'
// // Since we don't have access to the webpack config here is a dirty
// // way to avoid this limitation.
// // https://github.com/webpack/webpack/pull/10097#issuecomment-567116011
//
// // Unfortunately this workaround doesn't work with dynamic loading,
// // so we have to explicitly enumerate all catalogs here.
// const catalogs = {
//     en: async () => {
//         const {messages} = await import(
//             // @ts-ignore
//             `./file.js!=!@lingui/loader!./locales/en/messages.po`
//             )
//         return messages
//     },
//     hi: async () => {
//         const {messages} = await import(
//             // @ts-ignore
//             `./file.js!=!@lingui/loader!./locales/hi/messages.po`
//             )
//         return messages
//     },
//     mr: async () => {
//         const {messages} = await import(
//             // @ts-ignore
//             `./file.js!=!@lingui/loader!./locales/mr/messages.po`
//             )
//         return messages
//     },
// }
//
// /**
//  * We do a dynamic import of just the catalog that we need
//  * @param locale any locale string
//  */
// export async function dynamicActivate(locale) {
//     const messages = await catalogs[locale]()
//     i18n.loadAndActivate({locale, messages})
// }
//
// // If not we can just load all the catalogs and do a simple i18n.active(localeToActive)
// // i18n.load({
// //   en: messagesEn,
// //   cs: messagesCs,
// // });
