import React, {useState} from 'react';
import {BaseEdge, EdgeLabelRenderer, getSmoothStepPath, useReactFlow} from 'reactflow';
import {addRelation, deleteRelation} from '../../service/relationApi';
import {relationHandle} from '../../utils/relation';
import {t} from "i18next";

const CustomEdgeLabel = ({id, label, labelBg, transform}) => {

    return (
        <EdgeLabelRenderer>
            <div
                style={{
                    position: "absolute",
                    transform: transform,
                    backgroundColor: labelBg
                }}
                className={`px-[0.75vw] py-[0.75vh] rounded-full`}
            >
                <p className={"text-[0.75vw] font-SOHNE_MONO_MEDIUM"}>
                    {t(`CONSTANTS.RELATIONSHIP.${label}`)}
                </p>
            </div>
        </EdgeLabelRenderer>
    );
}

const RelationEdge = ({id, data, markerEnd, label, style, ...props}) => {
    const instance = useReactFlow();
    const [edgePath, labelX, labelY] = getSmoothStepPath(props);
    const [relationshipType, setRelationshipType] = useState(label);

    const updateEdge = (data) => {
        instance.setEdges((edges) =>
            edges.map((edge) => {
                if (edge.id === id) {
                    console.log("id: ", id);
                    edge.relationshipType = data.relationshipType;
                    edge.label = data.label;
                    edge.sourceHandle = data.sourceHandle;
                    edge.targetHandle = data.targetHandle;
                }
                console.log("edge: ", edge);
                return edge;
            })
        );
    };

    const handleFormData = (e) => {
        if (e.target.value === 'Relation') {
            return;
        }
        const {name, value} = e.target;

        const relation = {
            id: id,
            source: props.source,
            target: props.target,
            sourceHandle: relationHandle[value].sourceHandle,
            targetHandle: relationHandle[value].targetHandle,
            relationshipType: value,
            relationshipDetails: data.relationshipDetails,
            type: "relation",
            markerEnd: {type: 'arrow', color: 'green', width: 20, height: 20},
            label: value,
        }

        addRelation(relation).then((res) => {
            if (res) {
                setRelationshipType(res.data.label);
                updateEdge(res.data);
                console.log("res: ", res);
            } else {
                console.log(res);
            }
        });
    }

    const deleteEdge = (evt) => {
        evt.stopPropagation();
        instance.setEdges((edges) => edges.filter((ed) => ed.id !== id));
    };

    const deleteEdgeFromDB = (evt) => {
        evt.stopPropagation();
        console.log("id: ", id);
        const reversedId = id.split('-').reverse().join('-');
        console.log("reversedId: ", reversedId);
        deleteRelation(id).then((res) => {
            if (res.status === 200) {
                deleteRelation(reversedId);
                instance.setEdges((edges) => edges.filter((ed) => (ed.id !== id && ed.id !== reversedId)));
            } else {
                console.log("error");

            }
        });
    };

    const getLabelTransformPosition = () => {
        if (props.sourceHandleId === "a") {
            return `translate(-50%, -175%) translate(${props.sourceX}px, ${props.sourceY}px)`;
        }

        if (props.sourceHandleId === "b") {
            return `translate(-125%, -50%) translate(${props.sourceX}px, ${props.sourceY}px)`;
        }

        if (props.sourceHandleId === "c") {
            return `translate(25%, -50%) translate(${props.sourceX}px, ${props.sourceY}px)`;
        }

        if (props.sourceHandleId === "d") {
            return `translate(-50%, 75%) translate(${props.sourceX}px, ${props.sourceY}px)`;
        }
    }

    // useEffect(() => {
    //     console.log("Props in Edge: ", props);
    //     console.log("Edge Id: ", id);
    //     console.log("Edge Data: ", data);
    //     console.log("Edge Label: ", label);
    //     console.log("Edge Marker End: ", markerEnd);
    //     console.log("Edge Label Color: ", relationHandle[relationshipType].color);
    // }, []);

    return (
        <>
            <BaseEdge
                id={id}
                path={edgePath}
                markerEnd={markerEnd}
                style={style}
                interactionWidth={20}
            />
            {/*{relationshipType ?*/}
            <CustomEdgeLabel
                label={relationshipType}
                labelBg={relationHandle[relationshipType].color}
                id={id}
                transform={getLabelTransformPosition()}
            />
            {/*<CustomEdgeLabel*/}
            {/*    label={relationshipType}*/}
            {/*    labelX={getLabelYPosition()}*/}
            {/*    labelY={getLabelXPosition()}*/}
            {/*    labelBg={relationHandle[relationshipType].color}*/}
            {/*    id={id}*/}
            {/*/>*/}
            {/*    :*/}
            {/*    <foreignObject*/}
            {/*        width="80"*/}
            {/*        height="40"*/}
            {/*        x={labelX - 80}*/}
            {/*        y={labelY}*/}
            {/*    >*/}
            {/*        <select*/}
            {/*            onChange={(e) => handleFormData(e)}*/}
            {/*            id="relationshipType"*/}
            {/*            name="relationshipType"*/}
            {/*            required*/}
            {/*            className=" group bg-gray-50 border border-green-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1"*/}
            {/*        >*/}
            {/*            <option className="block py-1 px-0 ">Relation</option>*/}
            {/*            <option value="brother">Brother</option>*/}
            {/*            <option value="sister">Sister</option>*/}
            {/*            <option value="father">Father</option>*/}
            {/*            <option value="mother">Mother</option>*/}
            {/*            <option value="son">Son</option>*/}
            {/*            <option value="daughter">Daughter</option>*/}
            {/*            <option value="husband">Husband</option>*/}
            {/*            <option value="wife">Wife</option>*/}
            {/*            <option value="adoped-son">Adoped Son</option>*/}
            {/*            <option value="adoped-daughter">Adopted Daughter</option>*/}
            {/*        </select>*/}
            {/*    </foreignObject>*/}
            {/*}*/}

            <foreignObject
                x={labelX}
                y={labelY - 7}
                className='group w-[1vw] h-[1vw]'

            >
                <button
                    className="bg-green-100 border-gray-300 text-gray-900 rounded-full hover:bg-red-400 block w-full p-1"
                    onClick={(event) => relationshipType ? deleteEdgeFromDB(event) : deleteEdge(event)}
                >
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>

                </button>
            </foreignObject>
        </>
    );
};

export default RelationEdge;
